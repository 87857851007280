import React, {useEffect} from "react";
import { StyledContentCard } from "../../Components/Style/StyledComponents";
import Navbar from "../../Components/Navbar/Navbar";
import ReactGA from "react-ga4";

const TermsAndCondition = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Terms and condition"});
  }, []);
  return (
    <>
      <Navbar />
      <StyledContentCard width="70%">
        <div className="container-extra" style={{ textAlign: "left" }}>
          <h1 className="h1" style={{textAlign:'center'}}>Terms and Conditions</h1>
          <p className="p">
            SLProperty.lk, hereafter referred to as "we" or "us," operates as an
            open platform for advertisers to publish property-related
            advertisements in Sri Lanka. Our website, SLProperty.lk (the
            "Website"), is owned and managed by SLProperty.lk Team. It is
            important to note that we do not partake in the creation of
            advertisement content, the products or services offered in these
            advertisements, or the actual transactions between buyers and
            sellers facilitated through these advertisements. Consequently, we
            do not exert control over the quality, safety, or legality of the
            items presented or the accuracy of the listings.
          </p>

          <p className="p">
            Our primary service is to display the advertisements posted by
            advertisers. Any interaction with an advertiser or the decision to
            reserve their services is entirely at your discretion. Once you
            initiate direct contact with an advertiser via email or telephone,
            your relationship with SLProperty.lk terminates.
          </p>

          <p className="p">
            We reserve the right (though we are not obligated) to reject,
            delete, or remove any advertisement at our sole discretion, for any
            reason or without specifying a reason. This action will primarily be
            taken against advertisements that violate our Terms and Conditions,
            employ inappropriate language, or engage in spamming activities on
            the site.
          </p>

          <p className="p">
            Any disputes arising in connection with the reservation, purchase,
            or sale of services/products showcased on SLProperty.lk are solely
            between the Buyer (the individual who directly purchased the service
            from the Seller) and the Seller (the individual who directly
            provided or sold the service to the Buyer). We do not mediate
            disputes between Buyers and Sellers and cannot be held liable for
            any claims of loss, damages, or wrongdoing on the part of either the
            Buyer or the Seller.
          </p>

          <p className="p">
            SLProperty.lk does not vet advertisers, guarantee their services, or
            assume responsibility for services offered by advertisers.
          </p>

          <p className="p">
            In no event will SLProperty.lk or its parent company be held liable
            for any indirect, consequential, special, incidental, or punitive
            damages, including, without limitation, liability for negligence or
            any damages resulting from the use of this website or any inability
            to use it, loss of profit, loss of data, or any other damages
            stemming from this agreement or your use of the service.
            SLProperty.lk cannot be held liable for the content presented on the
            website.
          </p>

          <p className="p">
            By using the SLProperty.lk website, both Buyers and Sellers release
            SLProperty.lk, its parent company, affiliates, subsidiaries,
            vendors, agents, and employees from any claims, liability, or causes
            of action related to or arising from sales, purchases, postings, or
            submissions.
          </p>

          <p className="p">
            SLProperty.lk does not guarantee that the service will be
            uninterrupted or error-free, that your data will not be lost, or
            that defects in the service will be corrected. The pages within this
            website may contain technical inaccuracies and typographical errors.
            We accept no responsibility for keeping the information on these
            pages up to date or for any failure to do so.
          </p>

          <p className="p">
            If you knowingly provide false details, post fake or fraudulent
            property advertisements, or impersonate another party on
            SLProperty.lk, we reserve the right to disclose your information to
            the affected third party, their agents, or law enforcement agencies
            and ban you from the site.
          </p>

          <p className="p">
            By using the Site, you agree to take sole responsibility for the
            accuracy of the information you provide to us for use by others. You
            also accept sole responsibility for any communication with Users or
            other participants, commit to using this Site only for lawful
            purposes in accordance with our terms and conditions, agree to
            respect our intellectual property rights, and undertake to provide
            us with accurate information as required for proper conduct while
            taking responsibility for the information you provide.
          </p>

          <p className="p">
            If you are dissatisfied with the service or disagree with any part
            of this agreement or the service, your exclusive remedy is to
            discontinue using the service.
          </p>
        </div>
      </StyledContentCard>
    </>
  );
};

export default TermsAndCondition;
