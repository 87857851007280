import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from './utils';
import useAuth from './hooks/useAuth';
import useAxiosPrivate from './hooks/useAxiosPrivate';


const PrivateRoute = ({component: Component, ...rest}) => {
    const {auth} = useAuth()
    useAxiosPrivate()
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            auth.user?.uuid ?
                <Component {...props} />
            : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;
