import React, { useEffect, useReducer, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { EyeOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  PageHeader,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Pagination,
} from "antd";
import { StyledContentCard } from "../../Components/Style/StyledComponents";
import "./styles.css";
import { tableReducer } from "./reducers/tableReducer";
import PostContainer from "../PostView/components/postContainer";
import Filter from "./components/filter";
import { getItemsForAdmin } from "../../Services/services";
import { changeStatus } from "../../Services/services";
import * as _ from "lodash";
import moment from "moment";
const { TextArea } = Input;

const Admin = () => {
  const [initialState] = useState({
    pending: [],
    inReview: [],
    accept: [],
    reject: [],
  });
  const [state, dispatch] = useReducer(tableReducer, initialState);
  const [selectedExtraButtons, setSelectedExtraButtons] = useState([]);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [totalItem, setTotalItem] = useState(0);
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  useEffect(() => {
    getItemsForAdmin({ ...filters, page, pageSize: 25 }).then(
      ({ data, total }) => {
        const payload = {
          pending: data["Pending"] || [],
          inReview: data["InReview"] || [],
          accept: data["Accept"] || [],
          reject: data["Reject"] || [],
        };
        setTotalItem(total);
        dispatch({ type: "FETCH_DATA", payload });
      }
    );
  }, [filters, page]);

  const onRejectFinish = async ({ reason }) => {
    await changeStatus(id, { status: "Reject", reason });
    setIsRejectModalOpen(false);
  };

  const showPostModal = () => {
    setIsPostModalOpen(true);
  };
  const showRejectModal = async (id) => {
    setId(id);
    setIsRejectModalOpen(true);
  };

  const handlePostModalCancel = () => {
    setIsPostModalOpen(false);
  };
  const handleRejectModalCancel = () => {
    setIsRejectModalOpen(false);
  };

  const handleNewWindowOpen = () => {
    window.open(
      `http://${window.location.host}/create-post`,
      "_blank",
      "location=yes,resizable=yes,scrollbars=yes,status=yes"
    );
  };
  const getExtraBtnsPending =
    (dispatch, handlePostModalCancel, payloadForPass) => () => {
      return [
        <Button
          key="2"
          type="primary"
          onClick={async () => {
            await changeStatus(payloadForPass._id, { status: "InReview" });
            dispatch({ type: "MOVE_TO_IN_REVIEW", payload: payloadForPass });
            handlePostModalCancel();
          }}
        >
          In Review
        </Button>,
      ];
    };
  const getExtraBtnsInReview =
    (dispatch, handlePostModalCancel, payloadForPass, handleNewWindowOpen) =>
    () => {
      return [
        <Button
          key="1"
          disabled={true}
          type="ghost"
          onClick={handleNewWindowOpen}
        >
          Edit
        </Button>,
        <Button
          key="2"
          type="primary"
          onClick={async () => {
            await changeStatus(payloadForPass._id, { status: "Accept" });
            dispatch({ type: "MOVE_TO_ACCEPT", payload: payloadForPass });
            handlePostModalCancel();
          }}
        >
          Accept
        </Button>,
        <Button
          key="3"
          danger
          type="primary"
          onClick={async () => {
            await changeStatus(payloadForPass._id, { status: "Reject" });
            dispatch({ type: "MOVE_TO_REJECT", payload: payloadForPass });
            handlePostModalCancel();
            showRejectModal(payloadForPass._id);
          }}
        >
          Reject
        </Button>,
      ];
    };
  const getExtraBtnsAccept =
    (dispatch, handlePostModalCancel, payloadForPass) => () => {
      return [
        <Button
          key="2"
          type="primary"
          onClick={async () => {
            await changeStatus(payloadForPass._id, { status: "InReview" });
            dispatch({ type: "MOVE_TO_INREVIEW", payload: payloadForPass });
            handlePostModalCancel();
          }}
        >
          In Review
        </Button>,
        <Button
          key="3"
          danger
          type="primary"
          onClick={async () => {
            dispatch({ type: "MOVE_TO_REJECT", payload: payloadForPass });
            handlePostModalCancel();
            showRejectModal(payloadForPass._id);
          }}
        >
          Reject
        </Button>,
      ];
    };

  const getExtraBtnsReject = (
    dispatch,
    handlePostModalCancel,
    payloadForPass
  ) => {
    return [
      <Button
        key="2"
        type="primary"
        onClick={async () => {
          await changeStatus(payloadForPass._id, { status: "InReview" });
          dispatch({ type: "MOVE_TO_IN_REVIEW", payload: payloadForPass });
          handlePostModalCancel();
        }}
      >
        In Review
      </Button>,
    ];
  };

  const pendingColumn = [
    {
      title: "Pending",
      dataIndex: "name",
      key: "name",
      render: (_, data) => {
        return (
          <div style={{ display: "block" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Title: {data.title}</span>
              <div style={{ padding: "0 23px" }}></div>
              <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  const extraButtons = getExtraBtnsPending(
                    dispatch,
                    handlePostModalCancel,
                    data
                  );

                  setSelectedExtraButtons(extraButtons);
                  setId((pre) => data._id);
                  showPostModal();
                }}
                icon={<EyeOutlined />}
              />
              <div style={{ padding: "3px" }}></div>
              <Button
                type="primary"
                onClick={async () => {
                  const extraButtons = getExtraBtnsInReview(
                    dispatch,
                    handlePostModalCancel,
                    data,
                    handleNewWindowOpen
                  );
                  setSelectedExtraButtons(extraButtons);
                  await changeStatus(data._id, { status: "InReview" });
                  dispatch({ type: "MOVE_TO_IN_REVIEW", payload: data });
                  showPostModal();
                }}
                size="small"
              >
                In Review
              </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Date :{moment(data.createdAt).format("YYYY-MM-DD")}</span>
              <span>Ref No: {data.refNo}</span>
              <img
                src={data.images[0]}
                alt="Avatar"
                style={{ maxWidth: "70%", height: "auto" }}
              />
            </div>
          </div>
        );
      },
    },
  ];
  const inReviewColumn = [
    {
      title: "In Review",
      dataIndex: "name",
      key: "name",
      render: (_, data) => {
        return (
          <div style={{ display: "block" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Title: {data.title}</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    const extraButtons = getExtraBtnsInReview(
                      dispatch,
                      handlePostModalCancel,
                      data,
                      handleNewWindowOpen
                    );
                    setSelectedExtraButtons(extraButtons);
                    setId((pre) => data._id);
                    showPostModal();
                  }}
                  icon={<EyeOutlined />}
                />
                <div style={{ padding: "5px" }}></div>
                <Button
                  type="primary"
                  onClick={async () => {
                    await changeStatus(data._id, { status: "Accept" });
                    dispatch({ type: "MOVE_TO_ACCEPT", payload: data });
                  }}
                  size="small"
                >
                  Accept
                </Button>
                <div style={{ padding: "5px" }}></div>
                <Button
                  danger
                  type="primary"
                  onClick={async () => {
                    await changeStatus(data._id, { status: "Reject" });
                    dispatch({ type: "MOVE_TO_REJECT", payload: data });

                    showRejectModal(data._id);
                  }}
                  size="small"
                >
                  Reject
                </Button>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Date :{moment(data.createdAt).format("YYYY-MM-DD")}</span>
              <span>Ref No: {data.refNo}</span>
              <img
                src={data.images[0]}
                alt="Avatar"
                style={{ maxWidth: "70%", height: "auto" }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const acceptColumn = [
    {
      title: "Accept",
      dataIndex: "name",
      key: "name",
      render: (_, data) => {
        return (
          <div style={{ display: "block" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Title: {data.title}</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    const extraButtons = getExtraBtnsAccept(
                      dispatch,
                      handlePostModalCancel,
                      data
                    );
                    setSelectedExtraButtons(extraButtons);
                    setId((pre) => data._id);
                    showPostModal();
                  }}
                  icon={<EyeOutlined />}
                />
                <div style={{ padding: "5px" }}></div>
                <Button
                  type="primary"
                  onClick={async () => {
                    await changeStatus(data._id, { status: "InReview" });
                    dispatch({ type: "MOVE_TO_IN_REVIEW", payload: data });
                  }}
                  size="small"
                >
                  In Review
                </Button>
                <div style={{ padding: "5px" }}></div>
                <Button
                  danger
                  type="primary"
                  onClick={async () => {
                    await changeStatus(data._id, { status: "Reject" });
                    dispatch({ type: "MOVE_TO_REJECT", payload: data });
                    showRejectModal(data._id);
                  }}
                  size="small"
                >
                  Reject
                </Button>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Date :{moment(data.createdAt).format("YYYY-MM-DD")}</span>
              <span>Ref No: {data.refNo}</span>
              <img
                src={data.images[0]}
                alt="Avatar"
                style={{ maxWidth: "70%", height: "auto" }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const rejectColumn = [
    {
      title: "Reject",
      dataIndex: "name",
      key: "name",
      render: (_, data) => {
        return (
          <div style={{ display: "block" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{data.title}</span>
              <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  const extraButtons = getExtraBtnsReject(
                    dispatch,
                    handlePostModalCancel,
                    data
                  );
                  setSelectedExtraButtons(extraButtons);
                  setId((pre) => data._id);
                  showPostModal();
                }}
                icon={<EyeOutlined />}
              />
              <div style={{ padding: "5px" }}></div>
              <Button
                type="primary"
                onClick={async () => {
                  await changeStatus(data._id, { status: "InReview" });
                  dispatch({ type: "MOVE_TO_IN_REVIEW", payload: data });
                }}
                size="small"
              >
                In Review
              </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Date :{moment(data.createdAt).format("YYYY-MM-DD")}</span>
              <span>Ref No: {data.refNo}</span>
              <img
                src={data.images[0]}
                alt="Avatar"
                style={{ maxWidth: "70%", height: "auto" }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Navbar />
      <Modal
        width={1200}
        footer={null}
        open={isPostModalOpen}
        onCancel={handlePostModalCancel}
      >
        <PostContainer
          visible={true}
          id={id}
          extraButtons={selectedExtraButtons}
        />
      </Modal>

      <Modal
        open={isRejectModalOpen}
        onCancel={handleRejectModalCancel}
        footer={null}
      >
        <Form onFinish={onRejectFinish} style={{ padding: "0 40px" }}>
          <Form.Item
            label="Reason"
            name="reason"
            rules={[{ required: true, message: "Please enter a reason" }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div className="transfer-view-container">
        <Filter setFilters={setFilters} filters={filters} />
        <StyledContentCard maxWidth="85%">
          <PageHeader title="Adds from users" />
          <div className="content-wrapper">
            <Row gutter={[{ lg: 12 }]}>
              <Col span={6}>
                <Table
                  dataSource={state.pending}
                  columns={pendingColumn}
                  pagination={false}
                />
              </Col>
              <Col span={6}>
                <Table
                  rowKey={"_id"}
                  dataSource={state.inReview}
                  columns={inReviewColumn}
                  pagination={false}
                />
              </Col>
              <Col span={6}>
                <Table
                  rowKey={"_id"}
                  dataSource={state.accept}
                  columns={acceptColumn}
                  pagination={false}
                />
              </Col>
              <Col span={6}>
                <Table
                  rowKey={"_id"}
                  dataSource={state.reject}
                  columns={rejectColumn}
                  pagination={false}
                />
              </Col>
            </Row>
          </div>
          <div className="pagination-container">
            <Pagination
              size="small"
              total={totalItem}
              current={page}
              defaultPageSize={25}
              onChange={setPage}
            />
          </div>
        </StyledContentCard>
      </div>
    </div>
  );
};

export default Admin;
