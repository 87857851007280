import { title } from "process";

export const cities = [
  "Anuradhapura",
  "Colombo",
  "Jaffna",
  "Kandy",
  "Galle",
  "Mannar",
  "Chavakachcheri",
  "Vavuniya",
  "Sri Jayewardenepura Kotte",
  "Dehiwela-Mount Lavinia",
  "Nuwara Eliya",
  "Polonnaruwa",
  "Point Pedro",
  "Valvettithurai",
  "Kurunegala",
  "Ratnapura",
  "Badulla",
  "Bandarawela",
  "Happutalle",
  "Trincomalee",
  "Kinniya",
  "Batticaloa",
  "Eravur",
  "Kattankudy",
  "Ampara",
  "Kalmunai",
  "Akkaraipattu",
  "Embilipitiya",
  "Kegalle",
  "Balangoda",
  "Matale",
  "Dambulla",
  "Kalutata",
  "Matara",
  "Hambantota",
  "Chilaw",
  "Kuliyapitiya",
  "Gampaha",
  "Negombo",
  "Homagama",
  "Kalutara",
  "Kilinochchi",
  "Monaragala",
  "Mullativu",
  "Puttalam",
];

export const allCities = {
  SriLanka: {
    Colombo: {
      mainCity: "Colombo",
      subCities: [
        "Maharagama",
        "Piliyandala",
        "Nugegoda",
        "Dehiwala",
        "Colombo 3",
        "Angoda",
        "Athurugiriya",
        "Avissawella",
        "Battaramulla",
        "Boralesgamuwa",
        "Colombo 1",
        "Colombo 10",
        "Colombo 11",
        "Colombo 12",
        "Colombo 13",
        "Colombo 14",
        "Colombo 15",
        "Colombo 2",
        "Colombo 4",
        "Colombo 5",
        "Colombo 6",
        "Colombo 7",
        "Colombo 8",
        "Colombo 9",
        "Godagama",
        "Hanwella",
        "Homagama",
        "Kaduwela",
        "Kesbewa",
        "Kohuwala",
        "Kolonnawa",
        "Kottawa",
        "Kotte",
        "Malabe",
        "Meegoda",
        "Moratuwa",
        "Mount Lavinia",
        "Nawala",
        "Padukka",
        "Pannipitiya",
        "Rajagiriya",
        "Ratmalana",
        "Talawatugoda",
        "Wellampitiya",
      ],
    },
    Kandy: {
      mainCity: "Kandy",
      subCities: [
        "Kandy City",
        "Katugastota",
        "Gampola",
        "Peradeniya",
        "Akurana",
        "Ampitiya",
        "Digana",
        "Galagedara",
        "Gelioya",
        "Kadugannawa",
        "Kundasale",
        "Madawala Bazaar",
        "Menikhinna",
        "Nawalapitiya",
        "Pilimatalawa",
        "Wattegama",
      ],
    },
    Galle: {
      mainCity: "Galle",
      subCities: [
        "Galle City",
        "Ambalangoda",
        "Elpitiya",
        "Bentota",
        "Baddegama",
        "Ahangama",
        "Batapola",
        "Hikkaduwa",
        "Karapitiya",
      ],
    },
    Ampara: {
      mainCity: "Ampara",
      subCities: ["Akkarepattu", "Ampara City", "Kalmunai", "Sainthamaruthu"],
    },
    Anuradhapura: {
      mainCity: "Anuradhapura",
      subCities: [
        "Anuradhapura City",
        "Kekirawa",
        "Medawachchiya",
        "Tambuttegama",
        "Eppawala",
        "Galenbindunuwewa",
        "Galnewa",
        "Habarana",
        "Mihintale",
        "Nochchiyagama",
        "Talawa",
      ],
    },
    Badulla: {
      mainCity: "Badulla",
      subCities: [
        "Badulla City",
        "Bandarawela",
        "Welimada",
        "Mahiyanganaya",
        "Hali Ela",
        "Diyatalawa",
        "Ella",
        "Haputale",
        "Passara",
      ],
    },
    Batticaloa: {
      mainCity: "Batticaloa",
      subCities: ["Batticaloa City"],
    },
    Gampaha: {
      mainCity: "Gampaha",
      subCities: [
        "Gampaha City",
        "Negombo",
        "Kadawatha",
        "Kiribathgoda",
        "Wattala",
        "Delgoda",
        "Divulapitiya",
        "Ganemulla",
        "Ja-Ela",
        "Kandana",
        "Katunayake",
        "Kelaniya",
        "Minuwangoda",
        "Mirigama",
        "Nittambuwa",
        "Ragama",
        "Seeduwa",
        "Veyangoda",
      ],
    },
    Hambantota: {
      mainCity: "Hambantota",
      subCities: [
        "Tangalla",
        "Beliatta",
        "Hambantota City",
        "Ambalantota",
        "Tissamaharama",
      ],
    },
    Jaffna: {
      mainCity: "Jaffna",
      subCities: ["Jaffna City", "Nallur", "Chavakachcheri"],
    },
    Kalutara: {
      mainCity: "Kalutara",
      subCities: [
        "Panadura",
        "Horana",
        "Kalutara City",
        "Bandaragama",
        "Matugama",
        "Alutgama",
        "Beruwala",
        "Ingiriya",
        "Wadduwa",
      ],
    },
    Kegalle: {
      mainCity: "Kegalle",
      subCities: [
        "Kegalle City",
        "Mawanella",
        "Warakapola",
        "Ruwanwella",
        "Rambukkana",
        "Dehiowita",
        "Deraniyagala",
        "Galigamuwa",
        "Kitulgala",
        "Yatiyantota",
      ],
    },
    Kilinochchi: {
      mainCity: "Kilinochchi",
      subCities: ["Kilinochchi City"],
    },
    Kurunegala: {
      mainCity: "Kurunegala",
      subCities: [
        "Kurunegala City",
        "Kuliyapitiya",
        "Narammala",
        "Wariyapola",
        "Ibbagamuwa",
        "Alawwa",
        "Bingiriya",
        "Galgamuwa",
        "Giriulla",
        "Hettipola",
        "Mawathagama",
        "Nikaweratiya",
        "Pannala",
        "Polgahawela",
      ],
    },
    Mannar: {
      mainCity: "Mannar",
      subCities: ["Mannar City"],
    },
    Matale: {
      mainCity: "Matale",
      subCities: [
        "Matale City",
        "Dambulla",
        "Galewela",
        "Ukuwela",
        "Pallepola",
        "Palapathwela",
        "Rattota",
        "Sigiriya",
        "Yatawatta",
      ],
    },
    Matara: {
      mainCity: "Matara",
      subCities: [
        "Matara City",
        "Akuressa",
        "Weligama",
        "Dikwella",
        "Hakmana",
        "Deniyaya",
        "Gandara",
        "Kamburugamuwa",
        "Kamburupitiya",
        "Kekanadurra",
      ],
    },
    Monaragala: {
      mainCity: "Monaragala",
      subCities: [
        "Monaragala City",
        "Wellawaya",
        "Bibile",
        "Buttala",
        "Kataragama",
      ],
    },
    Mullativu: {
      mainCity: "Mullativu",
      subCities: ["Mullativu City"],
    },
    NuwaraEliya: {
      mainCity: "Nuwara Eliya",
      subCities: ["Nuwara Eliya City", "Hatton", "Ginigathhena", "Madulla"],
    },
    Polonnaruwa: {
      mainCity: "Polonnaruwa",
      subCities: [
        "Polonnaruwa City",
        "Kaduruwela",
        "Hingurakgoda",
        "Medirigiriya",
      ],
    },
    Puttalam: {
      mainCity: "Puttalam",
      subCities: [
        "Chilaw",
        "Puttalam City",
        "Wennappuwa",
        "Nattandiya",
        "Marawila",
        "Anamaduwa",
        "Dankotuwa",
      ],
    },
    Ratnapura: {
      mainCity: "Ratnapura",
      subCities: [
        "Ratnapura City",
        "Embilipitiya",
        "Balangoda",
        "Pelmadulla",
        "Eheliyagoda",
        "Kuruwita",
      ],
    },
    Trincomalee: {
      mainCity: "Trincomalee",
      subCities: ["Trincomalee City", "Kinniya"],
    },
    Vavuniya: {
      mainCity: "Vavuniya",
      subCities: ["Vavuniya City"],
    },
  },
};

export const subCities = Object.values(allCities.SriLanka).flatMap(
  (city) => city.subCities.map(subCity => subCity + '-' + city.mainCity)
);

export const treeData = Object.values(allCities.SriLanka).map((city) => ({
  value: city.mainCity + '-' + 'city',
  title: city.mainCity,
  children: city.subCities.map((subCity) => ({
    value: subCity + '-' + 'subCity',
    title: subCity,
  })),
}));

export const mainCities = Object.keys(allCities.SriLanka)
export const areas = [
  "Downtown",
  "Suburbs",
  "Residential Communities",
  "Gated Communities",
  "Housing Estates",
  "City Center",
  "Business Districts",
  "Urban Developments",
];

export const bedrooms = [
  "1 Bedroom",
  "2 Bedrooms",
  "3 Bedrooms",
  "4 Bedrooms",
  "5 Bedrooms",
  "6+ Bedrooms",
];

export const max_prices = [
  15000, 30000, 50000, 100000, 1000000, 5000000, 10000000, 100000000,
];
