import React from "react";
import { List } from "react-content-loader";

const SkeltonList = () => {
  return Array(25)
    .fill(0, 0, 25)
    .map((_, index) => <List key={index} />);
};

export default SkeltonList;
