import React, { useEffect, useState } from "react";
import * as antd from "antd";
import "./Login.css";
import { useHistory } from "react-router-dom";
import ExternalLoginButtons from "./components/ExternalLoginButtons";
import { authProvider } from "../../Auth/AuthProvider";
import {
  isUserRegistered,
  postLogin,
  postLoginFirebase,
  postSignup,
} from "../../Services/services";
import useAuth from "../../hooks/useAuth";
import Navbar from "../../Components/Navbar/Navbar";
import ReactGA from "react-ga4";
const Login = () => {
  const { setAuth, auth } = useAuth();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Login"});
  }, []);
  const onFinish = async ({ email, password }) => {
    try {
      const { user } = await authProvider.authenticateUserByEmailPassword(
        email,
        password
      );
      const { uid, displayName } = user;
      const result = await postLogin({ email, password });
      setAuth((prev) => ({
        ...prev,
        user: { uuid: uid, email, name: displayName, isFirebase: true },
        ...result.data.tokenData,
      }));
    } catch (error) {
      console.log(error);
      if (error.code === undefined) {
        setErrorMessage("Please re-check your credentials and try again");
      } else if (error.code.includes("auth/user-not-found")) {
        setErrorMessage(
          "Invalid email. Please re-check your credentials and try again."
        );
      } else if (error.code.includes("auth/wrong-password")) {
        setErrorMessage(
          "Invalid password. Please re-check your credentials and try again."
        );
      } else {
        setErrorMessage(
          "Error. Please re-check your credentials and try again."
        );
      }
    }
  };

  useEffect(() => {
    const unsubscribe = authProvider.getUserLoggedState((user) => {
      if (user) {
        if (auth?.user) {
          history.push("/profile/my-account");
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, [auth]);

  const onFacebookLogin = async () => {
    try {
      const result_facebook = await authProvider.authenticateUserByFaceBook();
      console.log(result_facebook, "facebook");
      if (result_facebook?.user) {
        const { uid } = result_facebook.user;
        const token = await result_facebook.user.getIdToken();
        if (result_facebook._tokenResponse) {
          const { displayName, email } = result_facebook._tokenResponse;

          const { isRegisterd } = await isUserRegistered(uid);
          if (isRegisterd) {
            const result = await postLoginFirebase({ email, token });
            setAuth((prev) => ({
              ...prev,
              user: { uuid: uid, email, name: displayName, isFirebase: true },
              ...result.data.tokenData,
            }));
          } else {
            await postSignup({
              email,
              uuid: uid,
              name: displayName,
              isFirebase: true,
            });
            const result = await postLoginFirebase({ email, token });
            setAuth((prev) => ({
              ...prev,
              user: { uuid: uid, email, name: displayName, isFirebase: true },
              ...result.data.tokenData,
            }));
            // history.push("/profile/my-account");
          }
        }
      }
    } catch (error) {
      if (
        error?.code?.includes("auth/account-exists-with-different-credential")
      ) {
        antd.message.error("Account already exist with google login");
      } else {
        console.error(error);
      }
    }
  };

  const onGoogleLogin = async () => {
    try {
      const result_google = await authProvider.authenticateUserByGoogle();
      const { email, uid, displayName } = result_google.user;
      const token = await result_google.user.getIdToken();
      if (result_google?.user) {
        const { isRegisterd } = await isUserRegistered(uid);
        if (isRegisterd) {
          const result = await postLoginFirebase({ email, token });
          setAuth((prev) => ({
            ...prev,
            user: { uuid: uid, email, name: displayName, isFirebase: true },
            ...result.data.tokenData,
          }));
        } else {
          await postSignup({
            email,
            uuid: uid,
            name: displayName,
            isFirebase: true,
          });
          const result = await postLoginFirebase({ email, token });
          setAuth((prev) => ({
            ...prev,
            user: { uuid: uid, email, name: displayName, isFirebase: true },
            ...result.data.tokenData,
          }));
          // history.push("/profile/my-account");
        }
      }
    } catch (error) {
      if (
        error?.code?.includes("auth/account-exists-with-different-credential")
      ) {
        antd.message.error("Account already exist with facebook login");
      } else {
        console.error(error);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Navbar />
      <div className="login-page">
        <div className="login-box">
          <antd.Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <p className="form-title">Welcome back</p>
            <p>Login to the SLProperty.lk</p>
            <antd.Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <antd.Input style={{ height: "40px" }} placeholder="Username" />
            </antd.Form.Item>

            <antd.Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
              validateStatus={errorMessage ? "error" : ""}
              help={errorMessage || null}
            >
              <antd.Input.Password
                style={{ height: "40px" }}
                placeholder="Password"
              />
            </antd.Form.Item>

            <antd.Form.Item>
              <antd.Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                LOGIN
              </antd.Button>
              <antd.Divider dashed>Or</antd.Divider>
              <ExternalLoginButtons
                handleFacebookSignIn={onFacebookLogin}
                handleGoogleSignIn={onGoogleLogin}
              />
            </antd.Form.Item>
            <div style={{ justifyContent: "center", textAlign: "center" }}>
              If you haven't registered ?
              <antd.Button type="link" href="/register">
                Register
              </antd.Button>
            </div>
            <div style={{ justifyContent: "center", textAlign: "center" }}>
              <antd.Button type="link" href="/forget-pw">
                Don't you remember password ?
              </antd.Button>
            </div>
          </antd.Form>
          {/* <div className="illustration-wrapper">
          <img
            src="/images/login-banner.jpg"
            alt="Login"
          />
        </div> */}
        </div>
      </div>
    </>
  );
};

export default Login;
