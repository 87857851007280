import React from "react";
import "./DetailCard.css";
import { useHistory } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import { formatCurrency } from "../../Pages/CreatePostForm/components/InputPrice";
const ProfileDetailCard = (props) => {
  const {
    city,
    price,
    property_type,
    title,
    min = false,
    disable = false,
    extra,
    statusComp,
    image,
    refNo,
    defaultUrl,
    header,
    _id,
    property_category,
  } = props;

  const history = useHistory();
  return (
    <div className={"card-container"}>
      <div
        className={"card"}
        onClick={() => {
          if (!disable) {
            history.push(`/post-view/${_id}`);
          }
        }}
      >
        <Row style={{ width: "100%", textAlign: "left" }}>
          {/* NOTE - Conditional rendering for user profile */}
          <Col xs={8} sm={4} md={6} lg={6} xl={6}>
            <div className={"image-container"}>
              <img src={image} width={"100%"} loading="lazy" style={{ maxHeight: "120px" }} />
              <div className="centered">{statusComp}</div>
            </div>
            <p style={{textAlign:'center' , fontSize:'10px' , marginTop:'8px'}}>{refNo}</p>
          </Col>

          <Col xs={13} sm={18} md={16} lg={16} xl={16}>
            <div className={"container"}>
              <h3 className="title">
                <b> {title}</b>
              </h3>
              <h4>
                {property_type}
                {property_type === "Commercial" ? " space for " : " for "}
                {property_category}
              </h4>
              <h4>{city}</h4>

              <p id="price">{formatCurrency(parseFloat(price))}</p>
              {extra}
            </div>
          </Col>

          <Col xs={3} sm={2} md={2} lg={2} xl={2}>
            {header}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProfileDetailCard;
