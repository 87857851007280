import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

function NumericInput({value:_value,onChange}) {
  const [inputValue, setInputValue] = useState('');
  useEffect(()=>{
     setInputValue(_value)
  },[_value])
  const handleInputChange = (e) => {
    const value = e.target.value;
    
    // Check if the input is a valid number using a regular expression
    if (/^[0-9]*\.?[0-9]*$/.test(value)) {
      onChange(value)
      setInputValue(value);
    }
  };

  return (
    <Input
      value={inputValue}
      onChange={handleInputChange}
      placeholder="Enter a number"
    />
  );
}

export default NumericInput;