const MAX_WIDTH = 400;
const MAX_HEIGHT = 400;

export const readFileAsync = (file,contentType) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let image = new Image();

    reader.onload = () => {
      image.src = reader.result;
      image.onload = () => {
        let { height, width } = image;
        Promise.all([
          compressImage(image, MAX_HEIGHT / height, width, height,contentType),
          compressImage(image, MAX_WIDTH / width, width, height,contentType),
        ])
          .then(([firstCompress, secondCompress]) => {
            console.log(firstCompress.size, secondCompress.size);
            const compressedBlob =
              firstCompress.size < secondCompress.size
                ? firstCompress
                : secondCompress;
            resolve({url:URL.createObjectURL(compressedBlob),blob:compressedBlob});
          })
          .catch((err) => {
            console.error("File compress error:", err);
          });
      };
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
/**
 * @param image
 * Image object that going to be compress
 * @param scale
 * Aspect ratio that scale the image
 * @param initalWidth
 * Image initial width
 * @param initalHeight
 * Image initial height
 */

const compressImage = (image, scale, initalWidth, initalHeight,contentType) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");

    canvas.width = scale * initalWidth;
    canvas.height = scale * initalHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    ctx.canvas.toBlob((blob) => {
      resolve(blob);
    }, contentType);
  });
};
