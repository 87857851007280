import { GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { firebaseProvider } from "./Firebase";
class AuthProvider {
  firebaseProvider;
  googleProvider;
  facebookProvider;

  constructor(initializedFirebaseProvider) {
    this.firebaseProvider = initializedFirebaseProvider;
    this.googleProvider = new GoogleAuthProvider();
    this.facebookProvider = new FacebookAuthProvider();
  }

  authenticateUserByEmailPassword = async (email, password) => {
   const result = await this.firebaseProvider.firebaseAuth.signInWithEmailAndPassword(
      firebaseProvider.auth,
      email,
      password
    );
    return result
  };
  authenticateUserByGoogle = async (email, password) => {
    const result = await this.firebaseProvider.firebaseAuth.signInWithPopup(
      this.firebaseProvider.auth,
      this.googleProvider
    );

    return result;
  };
  authenticateUserByFaceBook = async (email, password) => {
    const result = await this.firebaseProvider.firebaseAuth.signInWithPopup(
      this.firebaseProvider.auth,
      this.facebookProvider
    );
    return result
  };

  userLogout = async () => {
    return this.firebaseProvider.firebaseAuth.signOut(
      this.firebaseProvider.auth
    );
  };

  getUserLoggedState = (callBack) => {
    return this.firebaseProvider.firebaseAuth.onAuthStateChanged(
      this.firebaseProvider.auth,
      callBack
    );
  };

  getCurrentUser = () => {
    const { currentUser } = this.firebaseProvider.auth;
    return currentUser;
  };

  sendPasswordResetEmail = (email) => {
    return this.firebaseProvider.firebaseAuth.sendPasswordResetEmail(
      this.firebaseProvider.auth,
      email
    );
  };
  getAuthHeaders = async () => {
    const { currentUser } = this.firebaseProvider.auth;

    if (currentUser === null) {
      throw new Error("User not logged in.");
    }

    const firebaseIdToken = await currentUser.getIdToken(
      /* forceRefresh */ true
    );

    return `Bearer ${firebaseIdToken}`;
  };
}

export const authProvider = new AuthProvider(firebaseProvider);
