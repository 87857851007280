import React, { useEffect, useState } from "react";
import { Input } from "antd";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
function InputPhone({ value, onChange }) {
  // Custom parser function to allow only integers
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (value) => {
    if (value && isPossiblePhoneNumber(value)) {
      onChange(value);
      setInputValue(value);
    }
  };

  return (
    <PhoneInput
      limitMaxLength={10}
      defaultCountry="LK"
      className="ant-input"
      value={inputValue}
      onChange={handleInputChange}
      autoComplete={false}
    />
  );
}

export default InputPhone;
