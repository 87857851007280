import * as _ from "lodash";
export const login = (token_key) => {
  localStorage.setItem("sl_rel_login_token", token_key);
};

export const user = (user_data) => {
  localStorage.setItem("user", JSON.stringify(user_data));
};

export const logout = () => {
  localStorage.removeItem("sl_rel_login_token");
  localStorage.removeItem("user");
};

export const isLogin = () => {
  if (localStorage.getItem("sl_rel_login_token")) {
    return true;
  }
  return false;
};

export const exportRefValue = (ref) => {
  localStorage.setItem("ref", ref);
};

export function arraysAreEqualByUpdatedAt(arr1, arr2) {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    // Check equality based on the "updatedAt" property
    return _.every(arr1, (item1) =>
      _.some(arr2, (item2) => item1.updatedAt === item2.updatedAt)
    );
  }
export function arraysAreEqualByDefaultUrl(arr1, arr2) {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    // Check equality based on the "updatedAt" property
    return _.every(arr1, (item1) =>
      _.some(arr2, (item2) => item1.defaultUrl === item2.defaultUrl)
    );
  }

export function updateArray(oldArray, newArray) {
  // Identify items to add and remove
  const itemsToAdd = _.differenceWith(newArray, oldArray, _.isEqual);
  const itemsToRemove = _.differenceWith(oldArray, newArray, _.isEqual);

  // Remove items from the old array
  _.pullAllWith(oldArray, itemsToRemove, _.isEqual);

  // Add items to the old array
  oldArray.push(...itemsToAdd);

  return oldArray;
}


