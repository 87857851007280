import React, { useEffect } from "react";
import { StyledContentCard } from "../../Components/Style/StyledComponents";
import Navbar from "../../Components/Navbar/Navbar";
import ReactGA from "react-ga4";
const AboutUs = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "About Us"});
  }, []);
  return (
    <>
      <Navbar />
      <StyledContentCard width="70%">
        <div className="container-extra" style={{ textAlign: "center" }}>
          <h1 className="h1" style={{ textAlign: "center" }}>
            About Us
          </h1>
          <br />
          <p className="p">
            SLProperty.lk is a trusted online platform that offers a wide range
            of properties for buying, selling, and renting in Sri Lanka. Our
            platform provides fast, accurate, and up-to-date information on
            properties, houses, apartments, lands, and commercial properties for
            sale or rent. We are committed to helping you make smart and
            informed property decisions. Whether you’re looking to buy, sell, or
            rent a property, SLProperty.lk is your one-stop destination for all
            your real estate needs in Sri Lanka
          </p>
        </div>
      </StyledContentCard>
    </>
  );
};

export default AboutUs;
