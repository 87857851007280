import React, { createContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
const AuthContext = createContext({});

const getInitialState = () => {
  const user_state = localStorage.getItem("user_state");

  return user_state ? JSON.parse(user_state) : {};
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(getInitialState);

  const TRACKING_ID = "G-2H5ZKKD719";

  ReactGA.initialize(TRACKING_ID);

 

  useEffect(() => {
    localStorage.setItem("user_state", JSON.stringify(auth));
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
