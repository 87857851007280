import React from 'react'
import { Switch, Route } from 'react-router-dom'
import MyAccount from './MyAccount'
import MyProfile from './MyProfile'
import Settings from './Settings'
const ProfileRouter = ({router}) =>{
    const defaultPath = '/profile'
    return (
         <Switch>
            <Route path={`${defaultPath}/my-account`}>
              <MyAccount />
            </Route>
            <Route path={`${defaultPath}/my-profile`}>
               <MyProfile/>
            </Route>
            {/* <Route path={`${defaultPath}/settings`}>
               <Settings/>
            </Route> */}
         </Switch>
    )
}

export default ProfileRouter 