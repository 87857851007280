import {
  PageHeader,
  Layout,
  Image,
  Descriptions,
  Typography,
  Tag,
  Row,
  Col,
  Button,
  Space,
} from "antd";
import { Carousel } from "react-carousel-minimal";
import React, { useState, useEffect } from "react";
import { StyledContentCard } from "../../../Components/Style/StyledComponents";
import "../styles.css";
import { WhatsAppOutlined, PhoneOutlined } from "@ant-design/icons";
import { getItem } from "../../../Services/services";
import moment from "moment";
import { formatCurrency } from "../../CreatePostForm/components/InputPrice";
import { Helmet } from "react-helmet";
const { Content } = Layout;

const { Title, Text } = Typography;

const PostContainer = ({ visible = false, extraButtons, id }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    getItem(id)
      .then((resp) => {
        setData(resp);
      })
      .catch((err) => {
        console.err(err);
      });
  }, [id]);

  const extraBtns = visible ? extraButtons : null;
  const contentStyleList =
    data.images?.map((image) => ({
      height: "160px",
      color: "#fff",
      lineHeight: "160px",
      textAlign: "center",
      image,
    })) || [];

  const getPropertyTypeItem = (property_category) => {
    switch (property_category) {
      case "House":
      case "Apartment":
      case "Villa":
      case "Annex":
      case "Rooms":
        return "Room";
      case "Land":
        return "Perches";
      case "Commercial":
        return "Square Feet";
      default:
        return "";
    }
  };
  const getPropertyTypeItemName = (property_category) => {
    switch (property_category) {
      case "House":
      case "Apartment":
      case "Villa":
      case "Annex":
      case "Rooms":
        return "Room count";
      case "Land":
        return "Land size";
      case "Commercial":
        return "Square Feet";
      default:
        return "";
    }
  };

  const content = (
    <>
      <Helmet>
          <title>{data.title}</title>
          <meta name="description" content={data.title}/>
        </Helmet>
      <PageHeader
        title={data.title || ""}
        subTitle={`Posted on ${moment(data.createdAt).format(
          "DD MMM h:mm a"
        )}, ${data.subCity}, ${data.city}`}
        extra={extraBtns}
      />
      <div className="content-wrapper">
        <Layout>
          <Row>
            <Col md={16} xs={24}>
              <Content className="content-layout">
                {data?.images && (
                  <Carousel
                    data={contentStyleList}
                    time={2000}
                    width="1600px"
                    height="350px"
                    className="carousel-container"
                    radius="10px"
                    slideNumber={true}
                    captionPosition="bottom"
                    automatic={true}
                    dots={true}
                    pauseIconColor="white"
                    pauseIconSize="40px"
                    slideBackgroundColor="darkgrey"
                    slideImageFit="contain"
                    thumbnails={true}
                    thumbnailWidth="100px"
                    style={{
                      textAlign: "center",
                      maxWidth: "900px",
                      maxHeight: "500px",
                      margin: "0px 0px 70px 0px",
                    }}
                  />
                )}
              </Content>
            </Col>
            <Col md={8} xs={24} className="sider-layout">
              <Descriptions bordered>
                <Descriptions.Item span={24}>
                  <Text type="secondary">
                    For {String(data.property_category)} by
                  </Text>{" "}
                  {data.author}
                </Descriptions.Item>

                <Descriptions.Item
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Space size={"small"}>
                    <PhoneOutlined
                      style={{
                        fontSize: "20px",
                        color: "black",
                        paddingRight: "5px",
                        transform: "rotate(90deg)",
                      }}
                    />
                    <a href={`tel:${data.phone_number}`}>{data.phone_number}</a>
                    {data.isWhatsApp && (
                      <a
                        href={`https://wa.me/${data?.phone_number.replace(
                          /\+/g,
                          ""
                        )}`}
                      >
                        <WhatsAppOutlined
                          style={{
                            fontSize: "20px",
                            color: "green",
                            paddingRight: "5px",
                          }}
                        />
                      </a>
                    )}
                  </Space>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Layout>

        <div className="details">
          <div className="price-wrapper">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 3 }}>
              <Col xl={9} md={9} xs={24}>
                <Title level={2} style={{ color: "#317DB5" }}>
                  {formatCurrency(parseFloat(data.price))}
                </Title>
              </Col>
              {/* <Col span={8}>
                <Text italic style={{ lineHeight: 3.4, fontSize: "normal" }}>
                  Negotiable
                </Text>
              </Col> */}
            </Row>
          </div>
          <div className="property-detail-wrapper">
            <Descriptions>
              <Descriptions.Item span={24} label="Address">
                <Text type="secondary">{data.address}</Text>
              </Descriptions.Item>

              <Descriptions.Item label="Category" span={24}>
                <Text type="secondary">
                  {data.property_type}
                  {data.property_type === "Commercial"
                    ? " space for "
                    : " for "}
                  {data.property_category}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item
                span={24}
                label={getPropertyTypeItemName(data.property_type)}
              >
                <Row style={{ width: "100%" }} gutter={30}>
                  <Col>
                    <Text type="secondary">
                      {`${data.property_count} ` +
                        getPropertyTypeItem(data.property_type)}
                    </Text>
                  </Col>
                  <Col xl={16} xs={24}>
                    {data.bathRoom && (data.property_type !== 'Land' || data.bathRoom !== 'Commercial') && (
                      <span>
                        {`Bath Room count:`}
                        <Text type="secondary">
                          {` ${data.bathRoom} Bathroom`}
                        </Text>
                      </span>
                    )}
                  </Col>
                </Row>
              </Descriptions.Item>
            </Descriptions>

            <Descriptions style={{ marginTop: "20px" }}>
              <Descriptions.Item className="full-details">
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <Title level={3}>Description</Title>
                  <Text type="secondary">{data.description}</Text>
                </div>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="post-view-container">
        {!visible ? (
          <StyledContentCard>{content}</StyledContentCard>
        ) : (
          <>{content}</>
        )}
      </div>
    </>
  );
};

export default PostContainer;
