import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, Select, DatePicker, Button } from "antd";
import { cities } from "../../../Components/Banner/data";
import useAuth from "../../../hooks/useAuth";
import { getUserInfo, updateUserInfo } from "../../../Services/services";
import moment from "moment";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const { Option } = Select;
const MyProfile = () => {
  const { auth } = useAuth();
  const { user } = auth;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useAxiosPrivate();
  function disabledDate(current) {
    return current && current.valueOf() > Date.now();
  }
  useEffect(() => {
    if (user?.uuid) {
      getUserInfo(user?.uuid).then((data) => {
        if (data) {
          const { dob, ...rest } = data;
          form.setFieldsValue({
            ...rest,
            dob: dob ? moment(dob, "YYYY-MM-DD") : "",
          });
        }
      });
    }
  }, []);
  const handleFinish = async (values) => {
    const { dob } = values;
    const formattedDate = dob.format("YYYY-MM-DD");
    try {
      setLoading(true);
      await updateUserInfo(user.uuid, { ...values, dob: formattedDate });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Form onFinish={handleFinish} form={form}>
      <Row gutter={[20, 3]}>
        <Col md={12}>
          <Form.Item
            label="Name"
            name={"name"}
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "Please enter name" }]}
            required={true}
          >
            <Input name="name" />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Phone"
            name={"phone"}
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "Please enter phone no" }]}
            required={true}
          >
            <Input name="phone" />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Gender"
            name={"gender"}
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "Please select gender" }]}
            required={true}
          >
            <Select>
              <Option key={1} value="Male">
                Male
              </Option>
              <Option key={2} value="Female">
                Female
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Date of Birth"
            name={"dob"}
            rules={[{ required: true, message: "Please select Date of birth" }]}
            required={true}
            labelCol={{ span: 24 }}
          >
            <DatePicker format={"YYYY/MM/DD"} disabledDate={disabledDate} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Location"
            name={"location"}
            rules={[{ required: true, message: "Please select location" }]}
            required={true}
            labelCol={{ span: 24 }}
          >
            <Select>
              {cities.map((value, index) => (
                <Option key={index} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Email" labelCol={{ span: 24 }}>
            <Input
              type="Email"
              value={user?.email || ""}
              disabled
              name="email"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={12}></Col>
        <Col offset={12} md={12}>
          <Row>
            <Col offset={20} style={{ paddingLeft: "2px" }}>
              <Button
                type="primary"
                loading={loading}
                disabled={loading}
                htmlType="submit"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default MyProfile;
