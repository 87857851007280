import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Radio,
  Select,
  Upload,
  PageHeader,
  Button,
  Space,
  Checkbox,
} from "antd";
import Navbar from "../../Components/Navbar/Navbar";
import { StyledContentCard } from "../../Components/Style/StyledComponents";
import "./styles.css";
import { WhatsAppOutlined } from "@ant-design/icons";
import { readFileAsync } from "../../utils/file";
import {
  getItem,
  postItems,
  storeUrls,
  updateItems,
  uploadFiles,
} from "../../Services/services";
import { axiosProvider } from "../../Services/AxiosProvider";
import useAuth from "../../hooks/useAuth";
import { useHistory, useParams } from "react-router-dom";
import { cities, subCities } from "../../Components/Banner/data";
import InputPrice from "./components/InputPrice";
import NumericInput from "./components/NumericInput";
import InputInt from "./components/InputInt";
import InputPhone from "./components/InputPhone";
import ReactGA from "react-ga4";

const { Item: FormItem } = Form;
const { Option } = Select;
const CreatePostForm = () => {
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Create Post",
    });
  }, []);
  const property_count = [
    { key: "1", value: "Rooms Count", type: "House" },
    { key: "2", value: "Perch Count", type: "Land" },
    { key: "3", value: "Rooms Count", type: "Apartment" },
    { key: "4", value: "Square feet", type: "Commercial" },
    { key: "5", value: "Rooms Count", type: "Villa" },
    { key: "6", value: "Rooms Count", type: "Annex" },
    { key: "7", value: "Rooms Count", type: "Rooms" },
  ];
  const { id } = useParams();
  const [selectedProperty, setSelectedProperty] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("sale");
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const [form] = Form.useForm();
  const history = useHistory();

  const validateMobileNumber = (_, value) => {
    const mobileRegex = /^\+94[0-9]{9}$/;
    if (value && !mobileRegex.test(value)) {
      return Promise.reject("Please enter a valid mobile number");
    }
    return Promise.resolve();
  };

  const validatorUploads = (_, value) => {
    if (fileList.length === 0) {
      return Promise.reject("Please upload image and try again");
    }
    return Promise.resolve();
  };
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  useEffect(() => {
    if (id) {
      getItem(id)
        .then(({ images, ...rest }) => {
          const selectedProperty = property_count.find(
            (propery) => propery.type === rest.property_type
          );
          setSelectedProperty(selectedProperty.key);
          form.setFieldsValue({
            ...rest,
            property_type: selectedProperty.key,
          });
          const imagesMapped = images.map((imageURL, index) => {
            const fileName = imageURL?.split("?")[0].split("/").pop();
            return {
              uid: index,
              name: fileName,
              status: "done",
              url: imageURL,
              size: 0,
              type: "image/png",
            };
          });
          setFileList(imagesMapped);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const handleFinish = async ({
    files = { fileList: [] },
    subCity,
    ...rest
  }) => {
    const fileListToBeUploaded = [];
    let signedURLsForDB = [];
    try {
      setLoading(true);
      //Image compression
      await Promise.all(
        files.fileList
          .filter((file) => !file.url)
          .map(async (file) => {
            const fileData = {
              fileExtension: file.name?.split(".").pop() || "",
              contentType: file.type,
              id: file.uid,
            };
            if (file.status !== "removed") {
              if (!file.url) {
                try {
                  const fileURL = await readFileAsync(
                    file.originFileObj,
                    file.contentType
                  );
                  fileData.fileURL = fileURL.url || undefined;
                  fileData.blob = fileURL.blob;
                } catch (error) {}
              } else {
                fileData.url = file.url;
              }
              fileListToBeUploaded.push(fileData);
            }
          })
      );

      if (files.fileList.length > 0) {
        const res = await axiosProvider.get(
          "/signed/urls?" +
            fileListToBeUploaded
              .map(
                (file) =>
                  `fileExtension=${file.fileExtension}&contentType=${file.contentType}`
              )
              .join("&")
        );
        //Upload files for google cloud storage
        if (fileListToBeUploaded.length !== 0) {
          signedURLsForDB = await uploadFiles(fileListToBeUploaded, res.data);
        }
      }

      const submit_details = {
        ...rest,
        subCity: subCity.split("-")[0],
        city: subCity.split("-")[1],
        ...{
          images: signedURLsForDB.concat(
            storeUrls(
              fileList.filter((_file) => _file.url).map((each) => each.url)
            )
          ),
        },
        uuid: auth.user.uuid,
      };

      if (!id) {
        await postItems(submit_details);
      } else {
        //TODO: Update function implement
        delete submit_details.uuid;
        await updateItems(id, submit_details);
      }
      setLoading(false);
      history.push("/profile/my-account");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Navbar />
      <div className="create-post-container">
        <StyledContentCard maxWidth="70%">
          <PageHeader
            title="Fill in the details"
            className="site-page-header"
          />
          <Form
            onFinish={handleFinish}
            form={form}
            initialValues={{ property_category: "sale" }}
          >
            <Row gutter={5}>
              <Col span={12} offset={6}>
                <FormItem
                  label={"Select Category"}
                  labelCol={{ span: 24 }}
                  rules={[
                    { required: true, message: "Please select category" },
                  ]}
                  required={true}
                  name={"property_category"}
                >
                  <Radio.Group
                    defaultValue={"sale"}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <Radio value={"sale"}>Sale</Radio>
                    <Radio value={"rent"}>Rent</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col span={12} offset={6}>
                <FormItem
                  label={"Property type"}
                  labelCol={{ span: 24 }}
                  rules={[
                    { required: true, message: "Please select property type" },
                  ]}
                  required={true}
                  name={"property_type"}
                >
                  <Select onSelect={(value) => setSelectedProperty(value)}>
                    {property_count
                      .filter(
                        (each) =>
                          (each.key != "6" && each.key != "7") ||
                          selectedCategory == "rent"
                      )
                      .map((each) => (
                        <Option value={each.key}>{each.type}</Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>
              {selectedProperty && (
                <>
                  <Col span={6} offset={6}>
                    <FormItem
                      label={
                        property_count.find(
                          (type) => type.key == selectedProperty
                        ).value
                      }
                      name={"property_count"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a valid number",
                        },
                      ]}
                      required={true}
                    >
                      {["1", "3", "5", "6", "7", "4"].includes(
                        selectedProperty
                      ) ? (
                        <InputInt />
                      ) : (
                        <NumericInput />
                      )}
                    </FormItem>
                  </Col>
                  {["1", "3", "5", "6", "7"].includes(selectedProperty) && (
                    <Col span={6}>
                      <FormItem
                        label="Bath rooms"
                        labelCol={{ span: 24 }}
                        name={"bathRoom"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a valid number",
                          },
                        ]}
                      >
                        <InputInt key={"some"} />
                      </FormItem>
                    </Col>
                  )}
                </>
              )}
              <Col span={12} offset={6}>
                <FormItem
                  label={"Address"}
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: "Please add address" }]}
                  required={true}
                  name={"address"}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12} offset={6}>
                <FormItem
                  label={"City"}
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: "Please add city" }]}
                  required={true}
                  name={"subCity"}
                >
                  <Select
                    options={subCities.map((city) => ({
                      label: city.split("-")[0],
                      value: city,
                    }))}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={12} offset={6}>
                <FormItem
                  label={"Mobile No:"}
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: "Please add mobile no" }]}
                  required={true}
                  name={"phone_number"}
                >
                  <InputPhone />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={12} offset={6}>
                <FormItem
                  labelCol={{ span: 24 }}
                  name={"isWhatsApp"}
                  valuePropName="checked"
                >
                  <Checkbox>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "20px",
                      }}
                    >
                      <WhatsAppOutlined
                        style={{
                          fontSize: "20px",
                          padding: "0 5px",
                          color: "green",
                        }}
                      />
                    </div>
                  </Checkbox>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={12} offset={6}>
                <FormItem
                  label={"Title"}
                  labelCol={{ span: 24 }}
                  rules={[
                    { required: true, message: "Please add title" },
                    { max: 80, message: "Maximum characters count 80" },
                    { min: 20, message: "minimum characters count 20" },
                  ]}
                  required={true}
                  name="title"
                >
                  <Input />
                </FormItem>
              </Col>

              <Col span={12} offset={6}>
                <FormItem
                  label={"Description"}
                  labelCol={{ span: 24 }}
                  rules={[
                    { required: true, message: "Please add description" },
                  ]}
                  required={true}
                  name={"description"}
                >
                  <Input.TextArea />
                </FormItem>
              </Col>

              <Col span={12} offset={6}>
                <FormItem
                  label={"Price"}
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: "Please add price" }]}
                  required={true}
                  name={"price"}
                >
                  <InputPrice />
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col span={20} offset={6}>
                <FormItem
                  label={"Add up to 5 photos"}
                  labelCol={{ span: 24 }}
                  required={true}
                  name={"files"}
                  rules={[{ validator: validatorUploads }]}
                >
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    maxCount={5}
                    accept=".png,.jpeg,.jpg"
                  >
                    {fileList.length <= 5 && "+ Upload"}
                  </Upload>
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col span={12} offset={6}>
                <Space wrap>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                  <Button
                    type="ghost"
                    onClick={() => {
                      history.push("/");
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </StyledContentCard>
      </div>
    </>
  );
};

export default CreatePostForm;
