import React, { useState,useEffect } from "react";
import * as antd from "antd";
import "./Login.css";
import { authProvider } from "../../Auth/AuthProvider";
import Navbar from "../../Components/Navbar/Navbar";
import ReactGA from "react-ga4";


const ForgetPw = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Forget Password"});
  }, []);
  const onFinish = async ({ email }) => {
    try {
      setLoading(true);
      authProvider.sendPasswordResetEmail(email);
      setLoading(false);
    } catch (error) {
      setErrorMessage("Error. Please re-check your Email and try again.");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Navbar />
      <div className="login-page">
        <div className="login-box">
          <antd.Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <p className="form-title">Reset Password</p>
            <p>Reset Your Password at SLProperty.lk</p>
            <antd.Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <antd.Input
                placeholder="Enter your Email"
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage || null}
              />
            </antd.Form.Item>

            <antd.Form.Item>
              <antd.Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Send Email
              </antd.Button>
            </antd.Form.Item>

            <div style={{ justifyContent: "center", textAlign: "center" }}>
              If you haven't registered ?
              <antd.Button type="link" href="/register">
                Register
              </antd.Button>
            </div>
          </antd.Form>
          {/* <div className="illustration-wrapper">
          <img
            src="https://mixkit.imgix.net/art/preview/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.png?q=80&auto=format%2Ccompress&h=700"
            alt="Login"
          />
        </div> */}
        </div>
      </div>
    </>
  );
};

export default ForgetPw;
