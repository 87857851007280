import React from "react";
import "./Footer.css";
import { CustomButton } from "../Button/Button";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
        Find Your Dream Home Today with Our Comprehensive Listings
        </p>
      </section>
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <Link to="/about-us">About us</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-condition">Terms and condition</Link>
            <Link to="/contact-us">Contact us</Link>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              SLProperty.lk
              {/* <i className="fab fa-typo3" /> */}
            </Link>
          </div>
          <small className="website-rights">SLProperty.lk © 2023</small>
          <div className="social-icons">
            <a
              className="social-icon-link facebook"
              href="https://web.facebook.com/people/SLpropertylk/61551850166828"
              target="_blank"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              className="social-icon-link instagram"
              href="https://www.instagram.com/slproperty.lk"
              target="_blank"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              className="social-icon-link youtube"
              href="https://www.youtube.com/@slpropertylk"
              target="_blank"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube" />
            </a>
            <a
              className="social-icon-link twitter"
              href="https://www.tiktok.com/@slproperty.lk"
              target="_blank"
              aria-label="TikTok"
            >
              <i class="fab fa-tiktok" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
