import React, {useEffect} from "react";
import { Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import "./ContactUs.css";
import Navbar from "../../Components/Navbar/Navbar";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
const { TextArea } = Input;

const ContactUs = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Contact Us"});
  }, []);

  const handleFinish = ({ name, email, phone, message }) => {
    let recipient = "contactus@slproperty.lk";
    let subject = `Customer Inquiry - ${name} - ${phone} - ${email}`;
    let body = message;

    window.location.href =
      "mailto:" +
      recipient +
      "?subject=" +
      encodeURIComponent(subject) +
      "&body=" +
      encodeURIComponent(body);
  };
  return (
    <>
    <Helmet>
      <title>Contact Us</title>
      <meta name="description" content="Ready to find your dream home in Sri Lanka? Connect with us for expert guidance on property listings. Your ideal space is just a click away! Contact us now."/>
    </Helmet>
      <Navbar />
      <div className="contact-us">
        <div className="contact-container">
          <div className="contact-form">
            <h2>Arrange a meeting with our agent to buy, sell or rent home.</h2>
            <Form onFinish={handleFinish}>
              <div>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Please enter your name" },
                  ]}
                >
                  <Input placeholder="Your Name" style={{ height: 55 }} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  name="email"
                  type="email"
                  rules={[
                    { required: true, message: "Please enter your email" },
                  ]}
                >
                  <Input placeholder="Your Email" style={{ height: 55 }} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  name="phone"
                  rules={[
                    { required: true, message: "Please enter your phone" },
                  ]}
                >
                  <Input placeholder="Your Phone" style={{ height: 55 }} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your message",
                    },
                  ]}
                >
                  <TextArea rows={5} placeholder="" />
                </Form.Item>
              </div>
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", height: 55 }}
                >
                  Send Message
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
