import React, { lazy, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import useAxiosPrivate from "./hooks/useAxiosPrivate";
import { getIsAdmin } from "./Services/services";
import { useHistory } from "react-router-dom";

const AdminRoute = ({ component: Component, ...rest }) => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  useAxiosPrivate();
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { isAdmin: isAdminResp } = await getIsAdmin(auth.user?.uuid);
        setIsAdmin(Boolean(isAdminResp));
        if (!isAdminResp) {
          history.push("/")
          throw new Error("User is not an Admin");
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    if (auth.user) {
      fetchData();
    }
    else{
      history.push("/")
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAdmin) {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default AdminRoute;
