import axios from "axios";
import { BASE_URL } from "../config";

class AxiosProvider {
  axiosInstance;
  constructor() {
    if (!BASE_URL) {
      throw new Error("base url not provided");
    } else {
      this.axiosInstance = axios.create({
        baseURL: BASE_URL,
        withCredentials: true,
      });
    }
  }

  async get(path) {
    const result = await this.axiosInstance.get(path);
    return result?.data;
  }

  async delete(path) {
    const result = await this.axiosInstance.delete(path);
    return result;
  }

  async post(path, data) {
    const result = await this.axiosInstance.post(path, data);
    return result;
  }

  async put(path, data) {
    const result = await this.axiosInstance.put(path, data);
    return result.data;
  }
}

export const axiosProvider = new AxiosProvider();
