import React, { useEffect, useState } from "react";
import "./Banner.css";
import { Form, Input, Button, Slider, Select, TreeSelect } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./Banner.css";
import Navbar from "../Navbar/Navbar";
import { allCities, cities, mainCities, max_prices, treeData } from "./data";
import { FilterOutlined } from "@ant-design/icons";
import { getItems, getItemsBySearch } from "../../Services/services";
import {
  formatCurrency,
  formatCurrencyWithoutFraction,
} from "../../Pages/EditPostForm/components/InputPrice";
import * as _ from "lodash";
import axios from "axios";
import {
  arraysAreEqualByDefaultUrl,
  arraysAreEqualByUpdatedAt,
  updateArray,
} from "../../utils";
const { Option } = Select;

const Banner = ({ setItems, page, setLoading, setPage }) => {
  const initState = { page, pageSize: 25 };
  const [filters, setFilters] = useState(initState);
  const [searchKey, setSearchKey] = useState("");
  const [form] = Form.useForm();
  useEffect(() => {
    if (filters) {
      setLoading(true);

      fetchImages();
    }
  }, [filters]);

  const fetchImages = async () => {
    try {
      const myApiCacheStore = await caches.open("my-api-cache");
      const myApiCache = await myApiCacheStore.match("/auth/items");
      if (myApiCache) {
        const myApiCacheData = await myApiCache.json();
        if (myApiCacheData) {
          const isGetFromCache =
            myApiCacheData.page === page && Object.keys(filters).length == 2;
            console.log(isGetFromCache,'Hii')
            console.log(myApiCacheData.page, page)
            console.log(Object.keys(filters))
          if (isGetFromCache) {
            setItems(myApiCacheData);
            setLoading(false);
          }
          const res = await getItems(filters);

          if (
            !(
              arraysAreEqualByUpdatedAt(myApiCacheData.data, res.data) &&
              arraysAreEqualByDefaultUrl(myApiCacheData.data, res.data)
            )
          ) {
            setItems((prev) => ({
              ...prev,
              ...res,
              ...(prev.total !== res.total
                ? (() => {
                    setPage(1)
                    return { total: res.total };
                  })()
                : {}),
            }));
            setLoading(false);
            const responseToCache = new Response(
              JSON.stringify({
                data: res.data,
                total: res.total,
                page: page,
              })
            );
            await myApiCacheStore.put("/auth/items", responseToCache);
          }
        }
      } else {
        const res = await getItems(filters);
        const responseToCache = new Response(
          JSON.stringify({
            data: res.data,
            total: res.total,
            page: 1,
          })
        );
        const mergedArray = res.data.reduce((accumulator, currentItem) => {
          return [...accumulator, currentItem.images[0]];
        }, []);
        setItems(res);
        setLoading(false);
        await myApiCacheStore.put("/auth/items", responseToCache);
        await myApiCacheStore.addAll(mergedArray);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setFilters({ ...filters, page });
  }, [page]);

  const handleBySearch = () => {
    setLoading(true);
    if (searchKey) {
      getItemsBySearch(searchKey, filters)
        .then((items) => {
          setItems(items);
          setLoading(false);
        })
        .catch((err) => {
          console.err(err);
        });
    } else {
      getItems(filters)
        .then((items) => {
          setItems(items);
          setLoading(false);
        })
        .catch((err) => {
          console.err(err);
        });
    }
  };
  return (
    <Form form={form}>
      <Navbar />
      <div className="banner-wrapper">
        <h1> Find Your Dream Home </h1>
        <h2> The best properties are published here </h2>
        <div className="input_box-container">
          <div className="input-box">
            <div></div>
            <div className="search-box">
              <Form.Item name={"search_box"}>
                <Input
                  placeholder="What are you looking for ?"
                  style={{ borderRadius: "20px" }}
                  prefix={<SearchOutlined />}
                  size="large"
                  onPressEnter={(e) => {
                    e.preventDefault();
                    handleBySearch();
                  }}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item>
                <Button
                  href="#ads-section"
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 35,
                  }}
                  onClick={handleBySearch}
                >
                  Find
                </Button>
              </Form.Item>
            </div>
          </div>
          <div className="input-box">
            <div>
              <Form.Item name="categories">
                <Select
                  placeholder="Categories"
                  onChange={(value) => {
                    setFilters({ ...filters, property_category: value });
                  }}
                >
                  <Option value="sale">Sale</Option>
                  <Option value="rent">Rent</Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item name="cities">
                <TreeSelect
                  onChange={(value) => {
                    const type = value.split("-")[1];
                    delete filters.subCity;
                    delete filters.mainCity;
                    setFilters({ ...filters, [type]: value.split("-")[0] });
                  }}
                  treeData={treeData}
                  placeholder="Cities "
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="areas">
                <Select
                  placeholder="Type"
                  onChange={(value) => {
                    setFilters({ ...filters, property_type: value });
                  }}
                >
                  <Option value="house">House</Option>
                  <Option value="land">Land</Option>
                  <Option value="room">Room</Option>
                  <Option value="apartment">Apartment</Option>
                  <Option value="commercial">Commercial</Option>
                  <Option value="villa">Villa</Option>
                  <Option value="annex">Annex</Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item name="max_price">
                <Select
                  placeholder="Max Price"
                  onChange={(value) => {
                    setFilters({ ...filters, price: value });
                  }}
                >
                  {max_prices.map((price, index) => (
                    <Option key={index} value={price}>
                      {formatCurrencyWithoutFraction(price).replace(/^LKR/, "")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div></div>
          </div>
          {Object.keys(filters).length > Object.keys(initState).length && (
            <div className="reset-box">
              <div>
                <Button
                  type="default"
                  icon={<FilterOutlined />}
                  size="small"
                  style={{
                    width: "25%",
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    alignItems: "center",
                    marginRight: "10px",
                    float: "right",
                    borderRadius: "10px",
                    height: 35,
                  }}
                  onClick={() => {
                    form.resetFields();
                    setFilters(initState);
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Form>
  );
};

export default Banner;
