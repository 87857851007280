import React, { useEffect, useState } from "react";
import { Button, Descriptions, Modal, Row, Col, Space, Pagination } from "antd";
import PostStatus from "./PostStatus";
import "./styles.css";
import useAuth from "../../../hooks/useAuth";
import { deletePost, getItems } from "../../../Services/services";
import EmptyContent from "./EmptyContent";
import { useHistory } from "react-router-dom";
import {
  DeleteOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ProfileDetailCard from "../../../Components/DetailCard/ProfileDetailsCard";
import SkeltonList from "../../Home/components/SkeltonList";
const MyAccount = () => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [items, setItems] = useState({ data: [], total: 0 });
  const [rejectInfo, setRejectInfo] = useState("");
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const handleRejectModalCancel = () => {
    setIsRejectModalOpen(false);
  };
  const showRejectModal = () => {
    setIsRejectModalOpen(true);
  };

  const { auth } = useAuth();
  const history = useHistory();
  useEffect(() => {
    if (auth.user?.uuid) {
      setLoading(true);
      getItems({ uuid: auth.user.uuid, pageSize: 25, page })
        .then((items) => {
          setItems(items);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [page]);

  const deleteAd = (id) => {
    Modal.confirm({
      title: "Delete",
      icon: <DeleteOutlined />,
      content: "Do you want to delete this ad?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => {
        delPost(id);
      },
    });
  };

  const delPost = async (id) => {
    try {
      await deletePost(id);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        open={isRejectModalOpen}
        onCancel={handleRejectModalCancel}
        footer={null}
      >
        <Descriptions>
          <Descriptions.Item label="Rejected Reason">
            {rejectInfo}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
      {loading && <SkeltonList />}
      {!loading && items.total === 0 ? (
        <EmptyContent />
      ) : (
        <div style={{ width: "100%" }}>
          <div className="pagination-container">
            <Pagination
              size="small"
              total={items.total}
              current={page}
              defaultPageSize={25}
              onChange={setPage}
            />
          </div>
          {items.data.map((data, index) => (
            <ProfileDetailCard
              key={index}
              {...data}
              image={data.images.find((img) => img.includes(data.defaultUrl))}
              statusComp={<PostStatus status={data.status} />}
              disable={true}
              min={true}
              header={
                <Space
                  style={{ display: "flex", flexDirection: "column" }}
                  size="small"
                >
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => {
                      history.push(`/post-view/${data._id}`);
                    }}
                    icon={<EyeOutlined />}
                  />
                  <Button
                    style={{
                      float: "right",
                      position: "relative",
                      top: "3px",
                      right: "1px",
                    }}
                    type="primary"
                    danger
                    disabled={data.status === "InReview"}
                    shape="circle"
                    onClick={() => deleteAd(data._id)}
                    icon={<DeleteOutlined />}
                  />
                </Space>
              }
              extra={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    type="primary"
                    style={{ maxWidth: "200px" }}
                    onClick={() => history.push(`/edit-post/${data._id}`)}
                  >
                    Edit{" "}
                  </Button>
                  <div style={{ height: "10px" }}></div>
                  {data.status === "Reject" && (
                    <Button
                      danger
                      size="small"
                      style={{ maxWidth: "200px" }}
                      onClick={() => {
                        showRejectModal();
                        setRejectInfo(data.reason || "");
                      }}
                    >
                      Reject Info
                    </Button>
                  )}
                </div>
              }
            />
          ))}
        </div>
      )}
    </>
  );
};

export default MyAccount;
