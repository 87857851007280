import React from "react";
import { Tag } from "antd";
import {startCase} from 'lodash'

const PostStatus = ({ status }) => {
  let statusColor = "";

  if (status === "accept") {
    statusColor = "green";
  } else if (status === "inreview") {
    statusColor = "orange";
  } else if (status === "reject") {
    statusColor = "red";
  } else if (status === "pending") {
    statusColor = "blue";
  } else if (status === "withdrawn") {
    statusColor = "default";
  }

  return <Tag color={statusColor}>{startCase(status)}</Tag>;
};

export default PostStatus;
