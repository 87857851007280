import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
export const formatCurrency = (numericValue) => {
  return new Intl.NumberFormat("si-LK", {
    style: "currency",
    currency: "LKR",
  }).format(Number(numericValue));
};
export const formatCurrencyWithoutFraction = (numericValue) => {
  return new Intl.NumberFormat("si-LK", {
    style: "currency",
    currency: "LKR",
    minimumFractionDigits:0
  }).format(Number(numericValue));
};

const InputPrice = ({ value, onChange }) => {
  const [_value, setValue] = useState(value || "");
  useEffect(() => {
    if (!isNaN(value)) {
      setValue(value);
    }
  }, [value]);

  const handleInputChange = (inputValue) => {
    setValue(inputValue);
  };

  const handleOnBlur = (e) => {
    const originalNumber = e.target.value.replace(/,/g, "");

    onChange(originalNumber);
  };

  return (
    <CurrencyFormat
      className="ant-input"
      value={_value}
      onBlur={handleOnBlur}
      thousandSeparator={true}
      prefix={""}
      isAllowed={(values) => values.value < 100000000}
      onValueChange={(values) => {
        const { formattedValue } = values;
        // onChange(value)
        handleInputChange(formattedValue);
      }}
    />
  );
};

export default InputPrice;
