import { PageHeader, Layout, Menu, Button, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { UserOutlined, ProfileOutlined } from "@ant-design/icons";
import { StyledContentCard } from "../../Components/Style/StyledComponents";
import "./styles.css";
import ProfileRouter from "./components/ProfileRouter";
import { Link, useLocation } from "react-router-dom";
import { PoweroffOutlined } from "@ant-design/icons";
import { authProvider } from "../../Auth/AuthProvider";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../../Services/services";
import ReactGA from "react-ga4";

const { Sider } = Layout;

const ProfileMenuList = ({ defaultRoute = "/my-account", handleLogOut }) => {
  return (
    <Menu mode="inline" selectedKeys={[defaultRoute]}>
      <Menu.Item key={"/my-account"}>
        <Link to={"/profile/my-account"}>
          <ProfileOutlined />
          <span>My Account</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={"/my-profile"}>
        <Link to={"/profile/my-profile"}>
          <UserOutlined />
          <span>My Profile</span>
        </Link>
      </Menu.Item>
      {/* <Menu.Item key={"/settings"}>
        <Link to={"/profile/settings"}>
          <UserOutlined />
          <span>Settings</span>
        </Link>
      </Menu.Item> */}
      <Menu.Item key={"logout"} onClick={handleLogOut}>
        <PoweroffOutlined color="red" />
        <span color="red">Logout</span>
      </Menu.Item>
    </Menu>
  );
};
const Profile = () => {
  let location = useLocation();
  const { setAuth, auth } = useAuth();
  const [screenSize, setScreenSize] = useState(null);
  const history = useHistory();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Profile"});
  }, []);

  const handleLogOut = async () => {
    try {
      await authProvider.userLogout();
      await logoutUser(auth.user.uuid);
      setAuth({});
      history.push("/");
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setScreenSize(screenWidth);
    };

    handleResize(); // Call initially to set the screen size

    window.addEventListener("resize", handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="profile-view-container">
        <StyledContentCard maxWidth="70%">
          <PageHeader
            title={auth.user.name || ""}
            subTitle="My Profile"
            className="profile-page-header"
            avatar={{
              src: "/images/avatar.svg",
              alt: "Student Avatar",
              size: 50,
              className: "profile-avatar",
            }}
            {...(screenSize < 766 && {
              extra: (
                <ProfileMenuList
                  className="mobile-menu-list"
                  defaultRoute={location.pathname.split("/profile")[1]}
                />
              ),
            })}
          />
          <Layout style={{ backgroundColor: "white" }}>
            <Sider className="profile-sider">
              <ProfileMenuList
                defaultRoute={location.pathname.split("/profile")[1]}
                handleLogOut={handleLogOut}
              />
            </Sider>
            <Row style={{ width: "100%" }}>
              <Col xl={3}></Col>
              <Col xl={15}>
                <ProfileRouter />
              </Col>
              <Col xl={6}></Col>
            </Row>
          </Layout>
        </StyledContentCard>
      </div>
    </>
  );
};

export default Profile;
