import PostContainer from "./components/postContainer";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";

const PostView = () => {
  const { id } = useParams();
  const history = useHistory();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Post View",
    });
  }, []);

  const handleGoBack = () => {
    console.log('Go back')
    history.goBack();
  };

  useEffect(() => {
    const handlePopstate = () => {
      handleGoBack();
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      <Navbar />
      <PostContainer id={id} />
    </>
  );
};

export default PostView;
