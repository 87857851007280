import { useEffect } from "react";
import { axiosProvider } from "../Services/AxiosProvider";
import useRefresh from "./useRefresh";
import useAuth from "./useAuth";
import { useHistory } from "react-router-dom";
import { authProvider } from "../Auth/AuthProvider";
import { logoutUser } from "../Services/services";

const useAxiosPrivate = () => {
  const refresh = useRefresh();
  const history = useHistory();
  const { auth, setAuth } = useAuth();
  useEffect(() => {
    const requestInterceptor =
      axiosProvider.axiosInstance.interceptors.request.use(
        (config) => {
          if (!config.headers["Authorization"]) {
            config.headers["Authorization"] = `Bearer ${auth.access_token}`;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );
    const responseInterceptor =
      axiosProvider.axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
          const prevRequest = error?.config;
          if (error?.response?.status === 401 && !prevRequest?.sent) {
            prevRequest.sent = true;

            refresh()
              .then(({ access_token: newAccessToken }) => {
                prevRequest.headers[
                  "Authorization"
                ] = `Bearer ${newAccessToken}`;
                return axiosProvider.axiosInstance(prevRequest);
              })
              .catch(async (e) => {
                console.error(e);
                setAuth({});
                history.push("/");
                await logoutUser(auth.user.uuid)
                await authProvider.userLogout();

              });
          }
        }
      );

    return () => {
      axiosProvider.axiosInstance.interceptors.request.eject(
        requestInterceptor
      );
      axiosProvider.axiosInstance.interceptors.response.eject(
        responseInterceptor
      );
    };
  }, []);

  return axiosProvider;
};

export default useAxiosPrivate;
