import React from "react";
import { Row, Col } from "antd";
import { StyledContentCard } from "../../../Components/Style/StyledComponents";
const ListView = ({ pagination, list }) => {
  return (
    <StyledContentCard maxWidth="70%">
      <Row style={{ textAlign: "center" }}>
        <Col xs={0} sm={0} md={5} lg={5} xl={5}>
        </Col>
        <Col xs={24}   sm={24} md={15} lg={15} xl={15}>
          {pagination}
        </Col>
        <Col xs={0} sm={0} md={4} lg={4} xl={4}>
        </Col>
      </Row>
      <Row style={{ textAlign: "center", minHeight: "300px" }}>
        <Col xs={0} sm={0} md={5} lg={5} xl={5}>
          {/* Need to implement ads containers */}
        </Col>
        <Col xs={24} sm={24} md={15} lg={15} xl={15}>
          {list}
        </Col>
        <Col xs={0} sm={0} md={4} lg={4} xl={4}>
          {/* Need to implement ads containers */}
        </Col>
      </Row>
    </StyledContentCard>
  );
};

export default ListView;
