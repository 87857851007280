import React from "react";
import { Button } from "antd";

const ExternalLoginButtons = ({ handleGoogleSignIn, handleFacebookSignIn }) => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button
        onClick={handleGoogleSignIn}
        icon={<i className="fab fa-google" style={{ paddingRight: "10px" }} />}
        type="link"
      >
        Continue with Google
      </Button>
      <Button
        onClick={handleFacebookSignIn}
        icon={
          <i className="fab fa-facebook" style={{ paddingRight: "10px" }} />
        }
        type="link"
      >
        Continue with Facebook
      </Button>
    </div>
  );
};

export default ExternalLoginButtons;
