import React, { useEffect ,useState} from "react";
import { Input } from "antd";
function InputInt({ value, onChange }) {
  // Custom parser function to allow only integers
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e) => {
    let value = e.target.value;

    // Remove any non-integer characters from the input
    value = value.replace(/\D/g, "");
    onChange(value)
    setInputValue(value);
  };

  return (
    <Input
      value={inputValue}
      placeholder="Enter an integer"
      onChange={handleInputChange}
    />
  );
}

export default InputInt;
