import React, { useState } from "react";
import "./DetailCard.css";
import { useHistory } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import { formatCurrency } from "../../Pages/CreatePostForm/components/InputPrice";
import { getItem } from "../../Services/services";
const DetailCard = (props) => {
  const {
    city,
    price,
    property_type,
    title,
    min = false,
    disable = false,
    extra,
    image,
    subCity,
    _id,
    property_category,
  } = props;

  const history = useHistory();
  const [_image, _setImage] = useState(image);
  async function handleImageError() {
    const myApiCacheStore = await caches.open("my-api-cache");
    await myApiCacheStore.delete(_image);
    const { images, defaultUrl } = await getItem(_id);
    const newImage = images.find((img) => img.includes(defaultUrl));
    const statee = await myApiCacheStore.delete(defaultUrl,{ignoreMethod:true,ignoreSearch:true,ignoreVary:true})
    await myApiCacheStore.addAll(images);
    _setImage(newImage);
  }
  return (
    <div className={"card-container"} key={_id}>
      <div
        className={`${disable ? "card-disable" : "card"}`}
        onClick={() => {
          if (!disable) {
            history.push(`/post-view/${_id}`);
          }
        }}
      >
        <Row style={{ width: "100%", textAlign: "left" }}>
          {/* NOTE - Conditional rendering for user profile */}
          <Col xs={6} sm={4} md={6} lg={6} xl={6}>
            <div className="inner-img">
              <img
                src={_image}
                width={"100%"}
                onError={handleImageError}
                style={{ maxHeight: "120px" }}
                loading="lazy"
              />
            </div>
          </Col>

          <Col xs={18} sm={11} md={18} lg={18} xl={18}>
            <div className={"container"}>
              <h3 className="title">
                <b> {title}</b>
              </h3>
              <h4>
                {property_type}
                {property_type === "Commercial" ? " space for " : " for "}
                {property_category}
              </h4>
              <h4>{subCity}, {city}</h4>

              <p id="price">{formatCurrency(parseFloat(price))}</p>
              {extra}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DetailCard;
