import * as _ from 'lodash'

const moveTo = (tables, state, action, status) => {
  const { payload } = action;
  const currentIndex = tables.findIndex((tableName) =>
    state[tableName].some((item) => item._id === payload._id)
  );
  const currentTable = tables[currentIndex];
  const updatedCurrentTable = state[currentTable].filter(
    (item) => item._id !== payload._id
  );
  const updatedDirectionTable = [...state[status], payload];

 
  return {
    ...state,
    [currentTable]: updatedCurrentTable,
    [status]: updatedDirectionTable,
  };
};

const fetchData = (action) =>{
 const {payload} = action;
 return {
  ...payload
 }
}

export const tableReducer = (state, action) => {
  const tables = Object.keys(state);

  switch (action.type) {
    case "MOVE_TO_PENDING":
      return moveTo(tables, state, action, "pending");
    case "MOVE_TO_ACCEPT":
      return moveTo(tables, state, action, "accept");
    case "MOVE_TO_REJECT":
      return moveTo(tables, state, action, "reject");
    case "MOVE_TO_IN_REVIEW":
      return moveTo(tables, state, action, "inReview");
    case "FETCH_DATA":
      return fetchData(action)
    default:
      return state;
  }
};
