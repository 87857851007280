import * as firebase from "firebase/app";
import * as firebaseAuth from "firebase/auth";
import { FIREBASE_CONFIG } from "../config.js";

export class FireBaseProvider {
  firebaseAuth = firebaseAuth;
  auth;
  constructor(config) {
    if (!firebase.getApps().length) {
      const app = firebase.initializeApp(config);
      this.auth = firebaseAuth.getAuth(app);
    }
  }
}

export const firebaseProvider = new FireBaseProvider(FIREBASE_CONFIG);
