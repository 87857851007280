import { Card} from "antd";
import styled, {css} from "styled-components";
export const StyledCard = styled(Card)`
  border-radius: 4px;
`;

export const StyledContentCard = styled(StyledCard)`
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.09);
  align-items: center;
  margin: 1% auto;
  ${({ maxWidth }) => css`
    @media (min-width: 576px) {
      max-width: ${maxWidth || '70%'};
    }
  `}
`;

export const StyledStepContentCard = styled(StyledContentCard)`
  .ant-card-body {
    padding: 0;
  }
`;

