import React, { useState, useEffect } from "react";
import * as antd from "antd";
import "./Register.css";
import { postSignup } from "../../Services/services";
import { useHistory } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import ReactGA from "react-ga4";

const Register = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Register"});
  }, []);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const onFinish = async (values) => {
    const { email, password, username: name } = values;
    try {
      setError("");
      setLoading(true);
      await postSignup({ name, email, password });
      setLoading(false);
      history.push("/login");
    } catch (error) {
      console.log(error);
      setLoading(false);
      console.error(error);
      setError("Email or User Name already available");
    }
  };

  return (
    <>
      <Navbar />
      <div className="Register-page">
        <div className="Register-box">
          <antd.Form
            name="Register-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <p className="form-title">Register</p>
            <p>Register to the SLProperty.lk</p>
            <antd.Form.Item
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <antd.Input
                style={{ height: "40px" }}
                placeholder="Username"
                type="name"
              />
            </antd.Form.Item>

            <antd.Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email" }]}
            >
              <antd.Input
                style={{ height: "40px" }}
                type="email"
                placeholder="Email"
              />
            </antd.Form.Item>

            <antd.Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 6, message: "Minimum characters 6" },
              ]}
            >
              <antd.Input.Password
                style={{ height: "40px" }}
                placeholder="Password"
              />
            </antd.Form.Item>

            <antd.Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your confirm password!",
                },
                { min: 6, message: "Minimum characters 6" },
              ]}
            >
              <antd.Input.Password
                style={{ height: "40px" }}
                placeholder="Confirm Password"
              />
            </antd.Form.Item>

            <antd.Form.Item>
              <antd.Button
                type="primary"
                htmlType="submit"
                className="Register-form-button"
                loading={loading}
              >
                Register
              </antd.Button>
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            </antd.Form.Item>
            <div style={{ justifyContent: "center", textAlign: "center" }}>
              Already registered? Please
              <antd.Button type="link" href="/login">
                Login
              </antd.Button>
            </div>
          </antd.Form>
          {/* <div className="illustration-wrapper">
          <img
            src="https://mixkit.imgix.net/art/preview/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.png?q=80&auto=format%2Ccompress&h=700"
            alt="Register"
          />
        </div> */}
        </div>
      </div>
    </>
  );
};

export default Register;
