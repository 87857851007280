import React, { useState, useEffect } from "react";
import DetailCard from "../../Components/DetailCard/DetailCard";
import Banner from "../../Components/Banner/Banner";
import Footer from "../../Components/Footer/Footer";
import "./Home.css";
import { Pagination } from "antd";
import ListView from "./components/Listview";
import ReactGA from "react-ga4";
import SkeltonList from "./components/SkeltonList";
import { Helmet } from "react-helmet";

const Home = (props) => {
  const [items, setItems] = useState({ data: [], total: 0 });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home",
    });
  }, []);
  return (
    <>
    <Helmet>
      <title>SLProperty.lk</title>
      <meta name="description" content="Find Your Dream Home"/>
    </Helmet>
      <Banner setItems={setItems} setPage={setPage} page={page} setLoading={setLoading} />
      <section className="main-section">
        <ListView
          pagination={
            <div className="pagination-container">
              <Pagination
                size="small"
                total={items.total}
                current={page}
                defaultPageSize={25}
                onChange={setPage}
              />
            </div>
          }
          list={
            !loading ? (
              items?.data?.map((detail) => {
                return (
                  <>
                    <DetailCard
                      key={detail._id}
                      {...{
                        city: detail.city,
                        subCity:detail.subCity,
                        price: `${detail.price} LKR`,
                        property_type: detail.property_type,
                        property_category: detail.property_category,
                        title: detail.title,
                        defaultUrl:detail.defaultUrl,
                        image: detail.images.find((img) =>
                          img.includes(detail.defaultUrl)
                        ),
                        _id: detail._id,
                        refNo: detail.refNo,
                      }}
                    />
                    <div></div>
                  </>
                );
              })
            ) : (
              <SkeltonList />
            )
          }
        />
      </section>

      <Footer />
    </>
  );
};

export default Home;
