import { useEffect } from "react";
import { axiosProvider } from "../Services/AxiosProvider";
import useAuth from "./useAuth";
const useRefresh = () => {
  //TODO: Need to implement useAuth
  const { auth } = useAuth();
  const refresh = async () => {
    const result = await axiosProvider.axiosInstance.get("/auth/refresh", {
      headers: {
        Authorization: `Bearer ${auth.refresh_token}`,
      },
      withCredentials: true,
    });
    return result?.data;
  };
  return refresh;
};

export default useRefresh;
