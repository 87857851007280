import React, { useEffect } from "react";
import { StyledContentCard } from "../../Components/Style/StyledComponents";
import Navbar from "../../Components/Navbar/Navbar";
import ReactGA from "react-ga4";
const Privacy = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Privacy"});
  }, []);
  return (
    <>
      <Navbar />
      <StyledContentCard width="70%">
        <div className="container-extra">
          <h1 className="h1" style={{ textAlign: "center" }}>
            Privacy Policy
          </h1>
          <br />
          <p className="p">
            <strong style={{ fontWeight: "bold" }}>
              1. Privacy at SLProperty.lk
            </strong>{" "}
            <br />
            <br />
            We highly value the privacy of our visitors and users at
            SLProperty.lk. Recognizing the significance of protecting your
            personal information, we have provided an overview of the types of
            personal data we collect when you use and visit SLProperty.lk and
            the measures we employ to secure your information.
          </p>

          <p className="p">
            <strong>2. Cookies and Web Beacons</strong>
            <br />
            <br />
            We utilize cookies to store information, such as your personal
            preferences, when you visit our site. This may involve displaying a
            popup message once during your visit or providing the ability to log
            in to some of our features, like My Account. Additionally, we may
            feature third-party advertisements on SLProperty.lk to support our
            site. Some of these advertisers may use technologies such as cookies
            and web beacons when they advertise on our site. This allows them,
            such as Google through the Google AdSense program, to collect
            information like your IP address, your internet service provider
            (ISP), the browser you used to access our site, and occasionally,
            whether you have Adobe Flash installed. This data is generally used
            for geotargeting purposes (e.g., displaying real estate ads to
            someone in Mirissa) or presenting specific ads based on the websites
            you've visited. You have the option to disable or selectively manage
            our cookies or third-party cookies through your browser settings.
            However, this may affect your ability to interact with our site and
            other websites. It might limit your capacity to log into accounts or
            use certain services or programs.
          </p>

          <p className="p">
            <strong>3. Information We Collect</strong>
            <br />
            <br />
            We may collect and process various types of information, which
            include: - <strong>Information You Provide to Us:</strong>
            <br />
            <br />
            You may provide us with information through various means, including
            filling out forms on our websites or apps, corresponding with us via
            phone, email, or live chat, registering for an account, subscribing,
            posting advertisements, contacting advertisers through forms or
            phone numbers on our websites or apps, engaging in forums or
            comments, registering for events, entering competitions, promotions,
            or surveys, providing feedback, or reporting issues related to an
            ad. This information may encompass personal details you add to your
            profile, such as your name, address, email address, phone number,
            profile picture, and username. -{" "}
            <strong>Information We Collect About You:</strong>
            <br />
            <br />
            When you use our websites or apps, we may also collect information
            about how you access and use the Service (referred to as “Usage
            Data”). This Usage Data can include details such as your computer’s
            Internet Protocol (IP) address, browser type, browser version, the
            pages of our Service you visit, the time and date of your visit, the
            duration of your visit to those pages, unique device identifiers,
            and other diagnostic data.
          </p>

          <p className="p">
            <strong>4. Use of Data</strong>
            <br />
            <br />
            We employ the collected data for several purposes: - To provide and
            maintain the Service - To notify you about changes to our Service -
            To facilitate your participation in interactive features of our
            Service - To provide customer support and assistance - To furnish
            analysis or valuable information that allows us to enhance the
            Service - To monitor the use of the Service - To detect, prevent,
            and address technical issues
          </p>

          <p className="p">
            <strong>5. Disclosure of Data</strong>
            <br />
            <br />
            We may disclose your Personal Data in good faith when such action is
            necessary to: - Comply with a legal obligation - Protect and defend
            the rights or property of SLProperty.lk - Prevent or investigate
            potential wrongdoing in connection with the Service - Safeguard the
            personal safety of Service users or the public - Protect against
            legal liability
          </p>

          <p className="p">
            <strong>6. Sharing Your Data with Third Parties</strong>
            <br />
            <br />
            In cases where you register for any of our events, we may share your
            contact information with partners involved in the respective event.
            Additionally, we may share your details under specific circumstances
            when required by law. Beyond these scenarios, we will not share your
            data with any third-party entities.
          </p>

          <p className="p">
            <strong>7. Links to Other Sites</strong>
            <br />
            <br />
            Our Service may contain links to sites not operated by us. Clicking
            on a third-party link will redirect you to that third party’s site.
            We highly recommend reviewing the Privacy Policy of every site you
            visit. We have no control over, and assume no responsibility for,
            the content, privacy policies, or practices of any third-party sites
            or services.
          </p>

          <p className="p">
            <strong>8. Requesting Removal of Your Personal Information</strong>
            <br /> If you wish to discontinue receiving information from us in
            the future, you can request to stop receiving emails by clicking the
            unsubscribe link included in many of our service and marketing
            emails or by sending your details to contactus@slproperty.lk.
            Moreover, if you wish to have your information completely removed
            (assuming we have no other obligations to retain it), please inform
            us via email at contactus@slproperty.lk, and we will carry out this
            request within seven working days.
          </p>

          <p className="p">
            <strong>9. Changes to This Privacy Policy</strong>
            <br />
            <br />
            We may update our Privacy Policy periodically. We will notify you of
            any changes by posting the revised Privacy Policy on this page and
            sending you an email notification. The "effective date" at the top
            of this Privacy Policy will also be updated. We encourage you to
            review this Privacy Policy regularly to stay informed about any
            changes. Changes to this Privacy Policy become effective when they
            are posted on this page.
          </p>

          <p className="p">
            <strong>10. Contact Us</strong>
            <br />
            <br />
            If you have any questions or concerns about this Privacy Policy,
            please don't hesitate to contact us. You can reach us at
            contactus@slproperty.lk.
          </p>
        </div>
      </StyledContentCard>
    </>
  );
};

export default Privacy;
