import { axiosProvider } from "./AxiosProvider";
import axios from "axios";

const getQueryString = (filters) => {
  let queryString = "";
  queryString = Object.keys(filters)
    .map((key) => {
      if (Array.isArray(filters[key])) {
        const arrayQuery = filters[key].map((arrQ) => `${key}=${arrQ}`);
        return arrayQuery.join("&");
      }
      return `${key}=${filters[key]}`;
    })
    .join("&");

  return queryString;
};

export const postLogin = (data) => {
  return axiosProvider.post("/auth/login", data);
};

export const postLoginFirebase = (data) => {
  return axiosProvider.post("/auth/firebase/login", data);
};
export const deletePost = (id) => {
  return axiosProvider.delete(`/auth/items/${id}`);
};

export const postSignup = (data) => {
  return axiosProvider.post("/auth/register", data);
};

export const isUserRegistered = (uid) => {
  return axiosProvider.get(`/auth/user/check/${uid}`);
};

export const getItems = (filters) => {
  const queryString = getQueryString(filters);
  return axiosProvider.get(
    "/auth/items" + (queryString ? `?${queryString}` : "")
  );
};
export const getItemsForAdmin = (filters) => {
  const queryString = getQueryString(filters);
  return axiosProvider.get(
    "/auth/admins/items" + (queryString ? `?${queryString}` : "")
  );
};

export const updateItems = (id, data) => {
  return axiosProvider.put(`/auth/items/${id}`, data);
};

export const getItem = (id) => {
  return axiosProvider.get(`/auth/items/${id}`);
};

export const getItemsBySearch = async (searchKey, filters) => {
  const queryString = getQueryString(filters);
  return axiosProvider.get(
    `/auth/items/search/${searchKey}${queryString ? `?${queryString}` : ""}`
  );
};

export const logoutUser = async (id) => {
  return axiosProvider.post(`/auth/logout/${id}`);
};

export const postItems = async (data) => {
  return axiosProvider.post("/auth/items", data);
};

export const getIsAdmin = async (uuid) => {
  return axiosProvider.get(`/auth/admin/${uuid}`);
};

export const changeStatus = async (id, data) => {
  return axiosProvider.put(`/auth/items/status/${id}`, data);
};

export const updateUserInfo = async (uuid, data) => {
  return axiosProvider.put(`/auth/user/info/${uuid}`, data);
};
export const getUserInfo = async (uuid) => {
  return axiosProvider.get(`/auth/user/info/${uuid}`);
};

export const uploadFiles = async (fileListToBeUploaded, signedURLs) => {
  try {
    const withSignedURLs = fileListToBeUploaded.map((file, index) => {
      return {
        url: signedURLs[index],
        blob: file.blob,
        contentType: file.contentType,
      };
    });
    await Promise.all(
      withSignedURLs.map((withSignedURL) => {
        return axios.put(withSignedURL.url, withSignedURL.blob, {
          headers: {
            "Content-Type": withSignedURL.contentType,
          },
        });
      })
    );
    return signedURLs.map((signUrl) => signUrl.split("?")[0]);
  } catch (error) {
    console.error("Upload files error: ", error);
  }
};

export const storeUrls = (urls) => urls.map((signUrl) => signUrl.split("?")[0]);
