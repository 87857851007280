import React from "react";
import {
  PageHeader,
  Select,
  Input,
  DatePicker,
  Form,
  Row,
  Col,
  Button,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { StyledContentCard } from "../../../Components/Style/StyledComponents";
const { Option } = Select;
const Filter = ({ setFilters, filters }) => {
  const [form] = Form.useForm();

  return (
    <StyledContentCard maxWidth="85%">
      <PageHeader style={{ padding: 0 }}>
        <Form form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Type" name="status">
                <Select
                  onChange={(value) => {
                    setFilters({ ...filters, status: value });
                    // filter = { ...filter, status: value };
                  }}
                >
                  <Option value="pending">Pending</Option>
                  <Option value="inReview">In Review</Option>
                  <Option value="accept">Accept</Option>
                  <Option value="reject">Reject</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Ref No" name="refNo">
                <Input
                  onChange={(e) => {
                    if (e.target.value) {
                      setFilters({ ...filters, refNo: e.target.value });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Phone Number" name="phone_number">
                <Input
                  onChange={(e) => {
                    if (e.target.value) {
                      setFilters({ ...filters, phone_number: e.target.value });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item label="Email" name="email">
                <Input />
              </Form.Item>
            </Col> */}
            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Form.Item label="Date" name="date">
                <DatePicker
                  style={{ width: "300px" }}
                  onChange={(selectedTime) => {
                    if (selectedTime) {
                      setFilters({
                        ...filters,
                        date: selectedTime.format("YYYY-MM-DD"),
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                icon={<FilterOutlined />}
              >
                Filter
              </Button>
              <div style={{ width: "20px" }}></div>
              <Button
                onClick={() => {
                  form.resetFields();
                  setFilters({});
                }}
              >
                Clear Filter
              </Button>
            </Col>
          </Row>
        </Form>
      </PageHeader>
    </StyledContentCard>
  );
};

export default Filter;
