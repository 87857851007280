import React, { useState, useEffect } from 'react';
import { CustomButton } from '../Button/Button';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';
import NormalLoginForm from '../../Pages/Login/Login';
import './Navbar.css';
import useAuth from '../../hooks/useAuth';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const {auth} = useAuth()
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            SLProperty.lk
            {/* <i className='fab fa-typo3' /> */}
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link
                to={auth?.user ? '/profile/my-account' :  '/login' }
                className='nav-links'
                onClick={closeMobileMenu}
              >
                My account
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/contact-us'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Contact us
              </Link>
            </li>
            {!button &&
              <li className='nav-item'>
                <Link
                  to="/create-post"
                  className='nav-links'
                >
                  POST YOUR AD
                </Link>
              </li>}
          </ul>
          {button && <CustomButton buttonStyle='btn--outline'>POST YOUR AD</CustomButton>}
        </div>
        <Modal
          title="Select your ad type"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={700}
          footer={[
            <Button
              key="close"
              onClick={handleCancel}
              type="primary"
            >
              Close
            </Button>
          ]}
        >
          {isLogged ? <div className='modal-button'>
            <h3>House or Building for Sale</h3>
            <h3>Rental</h3>
            <h3>Land</h3>
            <h3>Wanted/ Looking for property</h3>
            <h3>Agent</h3>
            <h3>Ideal Home</h3>
          </div> : <NormalLoginForm/>}
        </Modal>
      </nav>
    </>
  );
}

export default Navbar;
