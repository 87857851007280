import React, { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import App from "./App";
import "antd-country-phone-input/dist/index.css";
import { AuthProvider } from "./context/AuthProvider";

ReactDOM.render(
  <StrictMode>
        <App />
  </StrictMode>,
  document.getElementById("root")
);
