import React from 'react'
import { Image, Typography } from 'antd';
const {Title, Text} = Typography;
const EmptyContent = () => {
  return (
    <div className="empty-container">
      <div className="empty-box">
        <Image
          preview={false}
          src={"https://w.ikman-st.com/dist/img/all/shop/empty-1x-6561cc5e.png"}
        />
      </div>
      <div className="empty-text">
        <Title level={5}> You don't have any ads yet.</Title>
        <Text type="secondary">
          Click the "Post an ad now!" button to post your ad.
        </Text>
      </div>
    </div>
  );
};

export  default EmptyContent
