import React, { Component, Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Property from "./Pages/Property/Property";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import CreatePostForm from "./Pages/CreatePostForm/CreatePostForm";
import "./App.css";
import PostView from "./Pages/PostView/PostView";
import Profile from "./Pages/Profile/Profile";
import Admin from "./Pages/Admin/Admin";
import { AuthProvider } from "./context/AuthProvider";
import ForgetPw from "./Pages/Login/Forgetpw";
import AdminRoute from "./AdminRoute";
import TermsAndCondition from "./Pages/TermsAndCondition/TermsAndCondition";
import Privacy from "./Pages/Privacy/Privacy";
import AboutUs from "./Pages/AboutUs/AboutUs";
import EditPostForm from "./Pages/EditPostForm/EditPostForm";
import { Helmet } from "react-helmet";
class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <Helmet>
        <title>SLProperty.lk</title>
        <meta
          name="description"
          content="Explore your dream home in Sri Lanka on our property listings site. We offer a wide range of properties for sale and rent, including houses, apartments and lands."/>
        <meta name="keywords" content="sale, rent, maharagama, house for rent, house for rent kandy, house for rent nuwara eliya, house for sale, house for rent colombo, low price house for sale, urgent house for sale, house for rent in maharagama, house for rent in nugegoda, house for rent in dehiwala"/>  
      </Helmet>
        <AuthProvider>
          <Switch>
            <PublicRoute component={Login} path="/login" />
            <PublicRoute component={Register} path="/register" />
            <PublicRoute component={ContactUs} path="/contact-us" />
            <PublicRoute
              component={TermsAndCondition}
              path="/terms-condition"
            />
            <PublicRoute component={Privacy} path="/privacy-policy" />
            <PublicRoute component={AboutUs} path="/about-us" />

            <PublicRoute component={Home} path="/" exact />
            <PrivateRoute component={Property} path="/property" />
            <PrivateRoute component={CreatePostForm} exact path="/create-post" />
            <PrivateRoute component={EditPostForm} path="/edit-post/:id" />
            <PublicRoute  component={PostView} path="/post-view/:id" />
            <PrivateRoute component={Profile} path="/profile" />
            <PublicRoute component={ForgetPw} path="/forget-pw" />
            <Suspense fallback={<div>loading</div>}>
              <AdminRoute component={Admin} path="/admin" />
            </Suspense>
          </Switch>
        </AuthProvider>
      </BrowserRouter>
    );
  }
}

export default App;
